import { Account } from 'domain/model/account'
import { SignupAccount } from 'domain/usecases/signup-account'

export class SignupAccountService implements SignupAccount {
  remote: SignupAccount

  constructor(remote: SignupAccount) {
    this.remote = remote
  }

  signupAccount(params: Account): Promise<unknown> {
    return this.remote.signupAccount(params)
  }
}

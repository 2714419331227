import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { Redirect, Route, Switch } from 'react-router-dom'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/display.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/padding.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'

/* Theme variables */
import { getCurrentAccountAdapter, setCurrentAccountAdapter } from 'main/adapters/current-account-adapter'
import { makeAccessTokenCache } from 'main/factories/cache/set-access-token-cache-factory'
import {
  DashboardPage,
  ExplorePage,
  ForgotPasswordPage,
  GenePage,
  LoginPage,
  PatientPage,
  ReportPage,
  ResetPasswordPage,
  SignupPage,
} from 'main/factories/pages'
import { makeUserService } from 'main/factories/services/user-service-factory'
import { route } from 'main/routes'
import PrivateRoute from 'main/routes/private-route-proxy'
import PublicRoute from 'main/routes/public-route-proxy'
import { CurrentAccountState, currentAccountState } from 'presentation/hooks/current-account-atom'
import React, { useEffect } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { RecoilRoot } from 'recoil'
import './theme/global-style.scss'
import './theme/variables.scss'

setupIonicReact({
  mode: 'md',
})

const accessToken = makeAccessTokenCache()

const state: CurrentAccountState = {
  getCurrentAccount: getCurrentAccountAdapter,
  setCurrentAccount: setCurrentAccountAdapter,
}

const App: React.FC = () => {
  const userService = makeUserService()

  const logout = () => userService.logout(() => (window.location.href = route.login.url))

  const onIdle = () => {
    const currentAccount = state.getCurrentAccount()

    if (currentAccount) {
      logout()
      // eslint-disable-next-line no-console
      console.log(idleTimer.getElapsedTime())
    }
  }

  const idleTimer = useIdleTimer({
    onIdle,
    timeout: 10 * 60 * 1000,
    crossTab: true,
    leaderElection: true,
    syncTimers: 200,
  })

  useEffect(() => {
    const handleInvalidToken = (e: StorageEvent) => {
      if (e.key === 'access-token' && e.oldValue && !e.newValue) window.location.href = route.login.url
    }
    window.addEventListener('storage', handleInvalidToken)

    return function cleanup() {
      window.removeEventListener('storage', handleInvalidToken)
    }
  }, [])

  return (
    <IonApp>
      <RecoilRoot initializeState={({ set }) => set(currentAccountState, state)}>
        <IonReactRouter>
          <IonRouterOutlet>
            <Switch>
              <PublicRoute accessToken={accessToken} exact path={route.login.url} component={LoginPage} />
              <PublicRoute accessToken={accessToken} exact path={route.signUp.url} component={SignupPage} />
              <Route exact path={route.forgotPassword.url} component={ForgotPasswordPage} />
              <Route exact path={route.resetPassword.url} component={ResetPasswordPage} />
              <PrivateRoute accessToken={accessToken} exact path={route.dashboard.url} component={DashboardPage} />
              <PrivateRoute
                accessToken={accessToken}
                exact
                path={`${route.report.url}/${route.report.sub.id}`}
                component={ReportPage}
              />
              <PrivateRoute
                accessToken={accessToken}
                exact
                path={`${route.report.url}/${route.report.sub.id}/${route.report.sub.gene}`}
                component={GenePage}
              />
              <PrivateRoute accessToken={accessToken} exact path={`${route.explore.url}`} component={ExplorePage} />
              <PrivateRoute accessToken={accessToken} exact path={`${route.patient.url}`} component={PatientPage} />
              <Route exact path={'/'}>
                <Redirect to={route.login.url} />
              </Route>
              <Route exact path={'*'}>
                <Redirect to={route.dashboard.url} />
              </Route>
            </Switch>
          </IonRouterOutlet>
        </IonReactRouter>
      </RecoilRoot>
      <ToastContainer />
    </IonApp>
  )
}

export default App

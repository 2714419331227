import { ExploreResult } from 'domain/usecases/get-explore-results'
import { atom } from 'recoil'

export interface ExplorePageState {
  isLoadingExplore: boolean
  exploreResults: ExploreResult[]
  page: number
  size: number
  resultsQty: number
}

export const exploreAtom = atom<ExplorePageState>({
  key: 'exploreAtom',
  default: {
    isLoadingExplore: false,
    exploreResults: [],
    page: 1,
    size: 10,
    resultsQty: 0,
  },
})

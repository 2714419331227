import { ListPatientsParams, PatientItem } from 'domain/usecases/list-patients'
import { atom } from 'recoil'

type DashboardAtomState = {
  isLoadingPatients: boolean | null
  patients: PatientItem[]
  pageOptions: {
    current: number | null
    size: number | null
    quantity: number | null
  }
}

export const dashboardAtom = atom<DashboardAtomState>({
  key: 'dashboardAtomState',
  default: {
    isLoadingPatients: null,
    patients: [],
    pageOptions: {
      current: null,
      size: null,
      quantity: null,
    },
  },
})

export const dashboardFilterAtom = atom<ListPatientsParams>({
  key: 'dashboardFilterAtom',
  default: {
    name: '',
    search: '',
    status: [],
    treatment: [],
    cancer_type: [],
    genomic_variant: [],
    order_by: {
      field: 'changed',
      order: 'DESC',
    },
    size: 10,
    page: 1,
  },
})

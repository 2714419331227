import { IonButton, IonIcon, IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react'
import { closeOutline, filterCircleOutline } from 'ionicons/icons'
import { Title, Viewport } from 'presentation/components'
import Chip from 'presentation/components/chip/chip'
import { TypeaheadItem } from 'presentation/components/typeahead/typeahead'
import React from 'react'
import './explore-heading.scss'

interface ExploreHeading {
  headingTitle: string

  slotAction?: JSX.Element

  onFilterButtonClick: () => void

  slotSearch: JSX.Element
  slotSearchHide: boolean
  onDeleteSearch: () => void

  tokens: string[]
  onDeleteToken: (token: string) => void
  onClearTokens: () => void

  chips: {
    [key: string]: TypeaheadItem
  }
  onDeleteChip: (token: string) => void
  onClearAll: () => void

  orderByOptions: TypeaheadItem[]
  orderByValue: string | number
  orderByPlaceHolder: string
  onOrderByValueChange: (value: string | number) => void
}

const ExploreHeading: React.FC<ExploreHeading> = (props) => {
  const hasChips = props.chips && Object.values(props.chips).filter(Boolean).length
  const hasTokens = props.tokens && props.tokens.length
  const getActiveFiltersLength = hasChips || hasTokens

  const getActiveFilters: () => string[] | [] = () => {
    if (getActiveFiltersLength) return Object.keys(props.chips)
    return []
  }

  return (
    <section className="heading-wrapper">
      <Viewport>
        <div className="line title">
          <Title size="h1">{props.headingTitle}</Title>
          {props.slotAction}
        </div>

        <div className="line searchbar">
          <IonItem lines="none" className="search-item ion-no-padding">
            {props.slotSearch ? <></> : <IonLabel position="floating">Search</IonLabel>}

            <div className="chips-and-input">{props.slotSearch}</div>
            {!props.slotSearchHide && (
              <IonIcon icon={closeOutline} color="light" onClick={props.onDeleteSearch} slot="end" />
            )}
            <IonButton color="secondary" slot="end" size="default" onClick={props.onFilterButtonClick}>
              <IonIcon icon={filterCircleOutline} slot="start" />
              Filters
            </IonButton>
          </IonItem>

          <>
            <div className="divider"></div>

            <IonItem lines="none" className="orderby-item">
              <IonLabel position="stacked">Order by</IonLabel>
              <IonSelect
                placeholder={props.orderByPlaceHolder}
                name="orderby"
                value={props.orderByValue}
                onIonChange={(event) => props.onOrderByValueChange(event.target.value)}
              >
                {props.orderByOptions.map((option, index) => (
                  <IonSelectOption key={index} value={option?.value}>
                    {option?.label}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
          </>
        </div>

        <div className="line chips">
          {props?.tokens.map((token, index) => {
            return (
              <Chip key={index} onDeleteItem={() => props.onDeleteToken(token)}>
                {token}
              </Chip>
            )
          })}
          {getActiveFilters().map((key, index) => (
            <Chip key={index} onDeleteItem={() => props.onDeleteChip(key)}>
              {props.chips[key]?.label}
            </Chip>
          ))}

          {getActiveFiltersLength ? (
            <IonButton fill="clear" color="light" className="clear-button" onClick={() => props.onClearAll()}>
              Clear all
            </IonButton>
          ) : (
            <></>
          )}
        </div>
      </Viewport>
    </section>
  )
}

export default ExploreHeading

import { IonCard } from '@ionic/react'
import React from 'react'
import './card-background.scss'

type CardBackgroundProps = {
  children: React.ReactNode
  className: string
}

const CardBackground: React.FC<CardBackgroundProps> = (props) => {
  const getClassName = () => {
    const defaultName = 'card-background'

    return `${defaultName} ${props.className}`
  }

  return <IonCard className={getClassName()}>{props.children}</IonCard>
}

export default CardBackground

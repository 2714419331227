import { IonContent, IonInput, IonModal, IonPage, IonSpinner } from '@ionic/react'
import { FavoriteArticleParams } from 'domain/usecases/favorite-article'
import {
  ExploreResult,
  GetExploreResultsParamsDates,
  GetExploreResultsParamsOrderBy,
} from 'domain/usecases/get-explore-results'
import { ExploreService } from 'infra/services/explore-service'
import { PatientsService } from 'infra/services/patients-service'
import { UserService } from 'infra/services/user-service'
import { NotFoundExplore, Viewport } from 'presentation/components'
import Footer from 'presentation/components/footer/footer'
import Pagination from 'presentation/components/pagination/pagination'
import { Header } from 'presentation/organisms/header/header'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { useRecoilState } from 'recoil'
import ExploreHeading from '../../organisms/explore-heading/explore-heading'
import { HeadingFilter } from '../dashboard/organisms/heading/heading-atom'
import { exploreAtom } from './explore-atom'
import ExploreListItem from './explore-list/explore-list'
import ExploreTabs, { ExploreTabsTypes } from './explore-tabs/explore-tabs'
import './explore.scss'
import ModalExploreArticle from './modal-explore-article/modal-explore-article'
import ModalFilterExplore from './modal-filter-explore/modal-filter-explore'

interface ExplorePageProps {
  userService: UserService
  patientsService: PatientsService
  exploreService: ExploreService
}

const ExplorePage: React.FC<ExplorePageProps> = (props) => {
  const modalFilterExplore = useRef<HTMLIonModalElement>(null)
  const modalExploreArticle = useRef<HTMLIonModalElement>(null)

  const [isModalExploreOpen, setModalExporeOpen] = useState(false)
  const [isModalExploreArticleOpen, setExploreArticleOpen] = useState(false)

  const [state, setState] = useRecoilState(exploreAtom)

  const [search, setSearch] = useState('')
  const [tokens, setTokens] = useState<string[]>([])
  const [chips, setChips] = useState<HeadingFilter>({})
  const [orderBy, setOrderBy] = useState<GetExploreResultsParamsOrderBy>('relevance')
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [tab, setTab] = useState<ExploreTabsTypes>('pubmed')
  const [openAsIframe, setOpenAsIframe] = useState<boolean | null>(null)

  const [isLoadingFavoriteArticle, setIsLoadingFavorite] = useState<string>('')

  const [exploreResult, setExploreResult] = useState<ExploreResult>()
  const urlState = useHistory()

  const getSearch = () => {
    const query = new URLSearchParams(window.location.search)

    return query.get('search')
  }

  const getExploreResults = () => {
    setState((s) => ({ ...s, isLoadingExplore: true }))

    props.exploreService
      .getExploreResults({
        tab,
        page: currentPage,
        size: 10,
        treatment: chips.treatment?.value as string,
        dates: chips.dates?.value as GetExploreResultsParamsDates,
        cancer_type: chips.cancerType?.value as string,
        search: tokens,
        order_by: orderBy,
      })
      .then((results) => {
        setState((s) => ({
          ...s,
          exploreResults: results.items,
          size: Math.ceil(results.total / 10),
          resultsQty: results.total,
        }))
      })
      .catch(() => null)
      .finally(() => {
        setState((s) => ({ ...s, isLoadingExplore: false }))
      })
  }

  const onKeyDown: React.KeyboardEventHandler<HTMLIonInputElement> = (e) => {
    const { key } = e
    const trimmedInput = search.trim()

    if (key === 'Enter' && trimmedInput?.length) {
      e.preventDefault()
      setSearch('')
      setTokens([...(tokens as string[]), trimmedInput] as never[])
    }
  }

  const handleDeleteToken = (token: string) => {
    const currentTokens: string[] = JSON.parse(JSON.stringify(tokens))
    const newTokens = currentTokens.filter((c) => c !== token)

    setTokens(newTokens)
  }

  const handleDeleteChip = (chip: string) => {
    const currentChips = JSON.parse(JSON.stringify(chips))

    delete currentChips[chip]

    setChips(currentChips)
  }

  const getSearchDescription = () => {
    const tkns = getTokensAsString(', ')
    const chps = getChipsAsString

    if (tkns.length || chps.trim().length) return `Results for "${tkns} ${chps}"`.trim()
  }

  const openNewLink = (link: string) => window.open(link, '_blank')

  const getChipsAsString = `${chips.cancerType?.label || ''} ${chips.treatment?.label || ''}`

  const getTokensAsString = (joinBy: string) => (tokens && tokens.length && tokens.join(joinBy)) || ''

  const openAsco = () => {
    const ascoLink = 'https://old-prod.asco.org/search/site/'

    const filledAscoLink = `${ascoLink}${getChipsAsString}${getTokensAsString(' ')}`

    openNewLink(filledAscoLink)
  }

  const favoriteArticle = (params: FavoriteArticleParams): Promise<string> => {
    return props.exploreService.favoriteArticle(params)
  }



  useEffect(() => {
    getExploreResults()
  }, [tokens, chips, orderBy, currentPage, tab])

  useEffect(() => {
    prefillSearch()
  }, [])

  const prefillSearch = useCallback(() => {
    const search = getSearch() as string
    if (search && search.length) setTokens((t) => [...t, search])
  }, [])

  useEffect(() => {
    if (urlState.location.state) {
      const params = urlState.location.state as { cancerType: string; geneName: string }

      setChips((s) => ({
        ...s,
        cancerType: {
          label: params.cancerType,
          value: params.cancerType,
        },
        treatment: {
          label: params.geneName,
          value: params.geneName,
        },
      }))
    }
  }, [urlState])

  return (
    <IonPage className="explore-wrapper">
      <Header userService={props.userService} tab="explore" />
      <IonContent>
        <ExploreHeading
          headingTitle={'Search'}
          onFilterButtonClick={() => setModalExporeOpen(true)}
          //
          slotSearch={
            <IonInput
              placeholder="Search"
              onIonInput={(e) => setSearch(e.target.value as string)}
              value={search}
              onKeyDown={onKeyDown}
            />
          }
          slotSearchHide={false}
          onDeleteSearch={() => setSearch('')}
          onClearAll={() => {
            setChips({})
            setTokens([])
          }}
          //
          tokens={tokens}
          onDeleteToken={(token) => handleDeleteToken(token)}
          onClearTokens={() => setTokens([])}
          //
          chips={chips}
          onDeleteChip={(chip) => handleDeleteChip(chip)}
          //
          orderByOptions={[
            { label: 'Relevance', value: 'relevance' },
            { label: 'Date', value: 'newest' },
          ]}
          orderByValue={orderBy}
          orderByPlaceHolder={'Select'}
          onOrderByValueChange={(value) => setOrderBy(value as GetExploreResultsParamsOrderBy)}
        />

        <ExploreTabs tab={tab} onTabChange={(tab) => setTab(tab)} onOpenClick={() => openAsco()}></ExploreTabs>

        <Viewport>
          {state.isLoadingExplore ? (
            <div className="explore-page-spinner">
              <IonSpinner name="dots" />
            </div>
          ) : (
            <div className="ion-padding">
              {state.resultsQty ? (
                <>
                  <span className="results-summary">
                    {(currentPage - 1) * 10 + 1} - {Math.min(currentPage * 10, state.resultsQty)}
                    <> </>out of {state.resultsQty} {getSearchDescription()}
                  </span>
                  <br />
                </>
              ) : null}
              {state.exploreResults &&
                state.exploreResults.map((result, key) => (
                  <ExploreListItem
                    key={key}
                    item={result}
                    identifierPrefix={tab === 'pubmed' ? 'PMID: ' : ''}
                    onClick={() => {
                      if (tab === 'clinical-trials') {
                        window.open(result.link, '_newtab')
                        return false
                      } else setOpenAsIframe(false)
                      setExploreArticleOpen(true)
                      setExploreResult(result)
                    }}
                    onFavoriteArticle={() => {
                      const starred = !result.starred
                      setIsLoadingFavorite(result.identifier)

                      favoriteArticle({
                        articleId: result.identifier,
                        starred: !result.starred,
                      })
                        .then(() => {
                          const updatedResults: ExploreResult[] = JSON.parse(JSON.stringify(state.exploreResults))

                          setState((s) => ({
                            ...s,
                            exploreResults: updatedResults.map((r) =>
                              r.identifier === result.identifier ? { ...r, starred } : r
                            ),
                          }))
                        })
                        .catch(() => null)
                        .finally(() => setIsLoadingFavorite(''))
                    }}
                    isLoadingFavoriteArticle={isLoadingFavoriteArticle === result.identifier}
                  />
                ))}

              {!state.exploreResults.length && (
                <NotFoundExplore
                  title={'What are you searching for?'}
                  subtitle={'Search and filter and see the results for each source'}
                />
              )}

              {state.exploreResults.length ? (
                <Pagination
                  currentPage={currentPage}
                  quantity={state.size}
                  onNewPage={(newPage) => setCurrentPage(newPage)}
                />
              ) : null}
            </div>
          )}
        </Viewport>
      </IonContent>

      <IonModal
        trigger="trigger-modal-filter-explore"
        ref={modalFilterExplore}
        isOpen={isModalExploreOpen}
        onDidDismiss={(info) => {
          setModalExporeOpen(false)
          if (info.detail.data) setChips(info.detail.data)
        }}
        className="modal-filter-explore-wrapper"
      >
        {isModalExploreOpen ? (
          <ModalFilterExplore
            patientsService={props.patientsService}
            modal={modalFilterExplore}
            filterApplied={chips}
          />
        ) : null}
      </IonModal>

      <IonModal
        trigger="trigger-modal-filter-article"
        ref={modalExploreArticle}
        isOpen={isModalExploreArticleOpen}
        onDidDismiss={(info) => {
          setExploreArticleOpen(false)
          setExploreResult(undefined)
          if (info.detail.data && info.detail.data.shouldUpdate) getExploreResults()
        }}
        className="modal-full-size"
      >
        {isModalExploreArticleOpen ? (
          <ModalExploreArticle
            exploreResult={exploreResult as ExploreResult}
            modal={modalExploreArticle}
            exploreService={props.exploreService}
            openAsIframe={openAsIframe as boolean}
          />
        ) : null}
      </IonModal>

      <Footer />
    </IonPage>
  )
}

export default ExplorePage

import { IonIcon, IonSpinner } from '@ionic/react'
import { ExploreResult } from 'domain/usecases/get-explore-results'
import { star } from 'ionicons/icons'
import React from 'react'
import './explore-list.scss'

interface ExploreListItemProps {
  item: ExploreResult
  identifierPrefix: string
  isLoadingFavoriteArticle: boolean
  onClick: () => void
  onFavoriteArticle: () => void
}

const ExploreListItem: React.FC<ExploreListItemProps> = (props) => {
  const phaseClass = {
    'Phase 1': 'phase-1',
    'Phase 2': 'phase-2',
    'Phase 3': 'phase-3',
  }

  const makeContent = (str: string | string[]) => {
    return Array.isArray(str) ? str.join(' - ') : str
  }

  const makeSummary = (str: string) => {
    if (str.length > 560) return `${str.slice(0, 560)}...`
    return str
  }

  return (
    <section className="explore-page-list-item">
      <div className="epli-header">
        <span className="pmid">
          {props.identifierPrefix} {props.item.identifier}
        </span>
        <div>
          {props.item.phase &&
            props.item.phase.map((phase, key) => (
              <span key={key} className={`phase ${phaseClass[phase]}`}>
                {phase}
              </span>
            ))}
        </div>
      </div>

      <b className="epli-title" onClick={props.onClick}>
        {props.item.title}
      </b>

      <p>{makeSummary(props.item.summary)}</p>

      <div className="epli-footer">
        <span>
          {props.item.metadata &&
            props.item.metadata.map((metadata, index) =>
              metadata.content && metadata.content.length ? (
                <React.Fragment key={props.item.identifier + index + Math.random()}>
                  <span>
                    <b>{metadata.title}</b>: {makeContent(metadata.content)}
                  </span>{' '}
                  <br />
                </React.Fragment>
              ) : (
                <React.Fragment key={props.item.identifier + index + Math.random()}></React.Fragment>
              )
            )}
        </span>
        <div className="icon" onClick={props.onFavoriteArticle}>
          {props.isLoadingFavoriteArticle ? (
            <IonSpinner name="bubbles" style={{ width: 16, height: 16 }} />
          ) : (
            <IonIcon icon={star} className={props.item.starred ? 'favorite' : ''} />
          )}
        </div>
      </div>
    </section>
  )
}

export default ExploreListItem

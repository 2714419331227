import {
  IonButton,
  IonContent,
  IonFooter,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonSpinner,
  IonTextarea,
  IonToolbar,
  useIonToast,
} from '@ionic/react'
import { Patient } from 'domain/usecases/get-patient'
import { PatientsService } from 'infra/services/patients-service'
import { arrowForwardOutline, pencil } from 'ionicons/icons'
import { CommentsItem, ModalTitle } from 'presentation/components'
import React, { useEffect, useRef, useState } from 'react'
import DefaultCard from '../default-card/default-card'
import './last-outcome-progress.scss'

interface LastOutcomeProgressProps {
  isLoading: boolean
  title: string
  patientsService: PatientsService
  patient: Patient
  note: string
  onUpdatePatient: () => void
}

const LastOutcomeProgress: React.FC<LastOutcomeProgressProps> = (props) => {
  const modalEdit = useRef<HTMLIonModalElement>(null)
  const [isModalEditOpen, setModalEditOpen] = useState(false)
  const [note, setNote] = useState('')
  const [presentToast] = useIonToast()
  const [isLoading, setLoading] = useState(false)
  const hasNotes = props.patient.patient_notes && props.patient.patient_notes.length

  const addNewNote = () => {
    setLoading(true)
    props.patientsService
      .addNewNote({
        MRN: props.patient.MRN,
        note,
      })
      .then(() => {
        modalEdit.current?.dismiss({ wasSaved: true })
        props.onUpdatePatient()
      })
      .catch(() =>
        presentToast({
          message: 'There was an erro while trying to add new note. Please try again later!',
          duration: 5000,
        })
      )
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    setNote(props.note)
  }, [props.note])

  return (
    <>
      <DefaultCard isLoading={props.isLoading} title={props.title}>
        <section className="last-outcome-progress">
          {hasNotes ? (
            props.patient.patient_notes.map(({ note, created }, index) => (
              <CommentsItem key={index} note={note} created={created} fromText={''} />
            ))
          ) : (
            <div className="lop-empty">
              <span className="">No progress note yet.</span>
            </div>
          )}

          <IonButton size="small" expand="block" onClick={() => setModalEditOpen(true)}>
            <IonIcon icon={arrowForwardOutline} slot="end" />
            New Progress Note
          </IonButton>
        </section>
      </DefaultCard>

      <IonModal
        ref={modalEdit}
        isOpen={isModalEditOpen}
        onDidDismiss={() => {
          setModalEditOpen(false)
        }}
      >
        <IonContent className="ion-padding modal-new-progress-note">
          <ModalTitle icon={pencil} label="Add new note" />

          <section>
            <IonItem>
              <IonLabel position="stacked">Note</IonLabel>
              <IonTextarea
                rows={5}
                value={note}
                onIonChange={(e) => setNote(e.target.value as string)}
                maxlength={560}
              />
            </IonItem>
          </section>
        </IonContent>

        <IonFooter>
          <IonToolbar>
            <IonButton slot="end" fill="outline" onClick={() => setModalEditOpen(false)}>
              Cancel
            </IonButton>
            <IonButton slot="end" onClick={addNewNote} disabled={isLoading}>
              {isLoading ? <IonSpinner /> : 'Save'}
            </IonButton>
          </IonToolbar>
        </IonFooter>
      </IonModal>
    </>
  )
}

export default LastOutcomeProgress

import { EmailValidation } from 'main/validations/email-validation'
import { GetValidationError } from 'main/validations/get-validation-error'
import { PasswordValidation } from 'main/validations/password-validation'
import { RequiredValidation } from 'main/validations/required-validation'
import { StringSizeValidation } from 'main/validations/string-size-validation'
import { SignupFormValidation } from 'main/validations/usecases/signup-form-validation'

export const makeSignupFormValidations = (): SignupFormValidation => {
  const makeRequiredValidation = new RequiredValidation()

  return {
    name: [makeRequiredValidation, new StringSizeValidation(10, 60, 'name')],
    email: [makeRequiredValidation, new EmailValidation()],
    password: [makeRequiredValidation, new PasswordValidation()],
    role: [makeRequiredValidation],
    clinic: [makeRequiredValidation],
    getValidationError: new GetValidationError().getValidationError,
  }
}

import { IonChip, IonIcon } from '@ionic/react'
import { closeCircle } from 'ionicons/icons'
import React from 'react'
import './chip.scss'

type ChipProps = React.ComponentPropsWithoutRef<typeof IonChip> & {
  onDeleteItem: () => void
}

const Chip: React.FC<ChipProps> = (props) => {
  const triggerOnDeleteItem: React.MouseEventHandler<HTMLIonIconElement> = (ev) => {
    ev.stopPropagation()

    props.onDeleteItem()
  }

  return (
    <IonChip {...props}>
      {props.children} <IonIcon icon={closeCircle} onClick={triggerOnDeleteItem} />
    </IonChip>
  )
}

export default Chip

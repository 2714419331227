import { IonButton, IonIcon, IonModal, IonSkeletonText } from '@ionic/react'
import { Patient } from 'domain/usecases/get-patient'
import { toStatus } from 'domain/usecases/list-patients'
import { labs } from 'helpers/labs'
import { PatientsService } from 'infra/services/patients-service'
import { ReportsService } from 'infra/services/reports-service'
import { UserInternalService } from 'infra/services/user-internal-service'
import { shareSocial } from 'ionicons/icons'
import { route } from 'main/routes'
import { ButtonLink, ButtonRound } from 'presentation/components'
import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { useRecoilValue } from 'recoil'
import { reportAtom } from '../../report-atom'
import ModalEditReportHeader from '../modal-edit-report-header/modal-edit-report-header'
import ModalShareReport from '../modal-share-report/modal-share-report'
import './report-header.scss'

interface ReportHeaderProps {
  patientsService: PatientsService
  userInternalService: UserInternalService
  reportsService: ReportsService
  isViewOnly: boolean
  onEditHeaderDismiss: () => void
}

export const ReportHeader: React.FC<ReportHeaderProps> = (props) => {
  const { report, isLoadingReport, reportPdfLink } = useRecoilValue(reportAtom)
  const fallback = 'Unknown'
  const modalEdit = useRef<HTMLIonModalElement>(null)
  const [isModalEditOpen, setModalEditOpen] = useState(false)

  const [isOpenShare, setOpenShare] = useState(false)

  const navigate = useHistory()

  const Skeleton = (params: { size: number; height?: number }) => (
    <IonSkeletonText animated={true} style={{ width: params.size, height: params.height, display: 'inline-block' }} />
  )

  return (
    <section className="report-header">
      <div className="patient-line">
        <h1>{isLoadingReport ? <Skeleton size={300} height={30} /> : <b>{report?.patient?.name}</b>}</h1>

        {props.isViewOnly ? undefined : (
          <>
            {isLoadingReport ? undefined : (
              <ButtonRound color="tertiary" size="small" onClick={() => setOpenShare(true)}>
                <IonIcon icon={shareSocial} slot="start" />
                Share
              </ButtonRound>
            )}
          </>
        )}
      </div>

      <div className="report-header-content">
        <div className="left-side">
          <div className="subcontent">
            {isLoadingReport ? (
              <Skeleton size={150} />
            ) : (
              <>
                <span>{report?.patient?.sex || fallback} - </span>{' '}
                <span>{report?.patient?.age ? `${report?.patient?.age}yo` : fallback} | </span>
                <span>#{report?.patient?.MRN || fallback} | </span>
                {!props.isViewOnly ? (
                  <>
                    <ButtonLink
                      onClick={() => {
                        navigate.push(`${route.patient.url}`, {
                          patientItem: report?.patient,
                        })
                      }}
                      className="patient-link"
                    >
                      See Patient Detail
                    </ButtonLink>
                  </>
                ) : undefined}
              </>
            )}
          </div>

          <div className="report-header-card">
            <div className="card-item">
              <span className="label card-item-label">Cancer Type:</span>
              {isLoadingReport ? (
                <Skeleton size={150} />
              ) : (
                <b>{report?.patient?.cancer_type || report?.diagnosis || fallback}</b>
              )}
            </div>

            <div className="content">
              <div className="card-item">
                <span className="label card-item-label">Stage:</span>

                {isLoadingReport ? <Skeleton size={150} /> : <b>{report?.patient?.cancer_stage || fallback}</b>}
              </div>

              <div className="card-item">
                <span className="label card-item-label">Status:</span>
                {isLoadingReport ? (
                  <Skeleton size={150} />
                ) : (
                  <b>{report?.patient?.status ? toStatus[report?.patient?.status] : fallback}</b>
                )}
              </div>

              {props.isViewOnly || isLoadingReport ? (
                <div></div>
              ) : (
                <IonButton
                  fill="outline"
                  size="small"
                  onClick={() => setModalEditOpen(true)}
                  disabled={!!isLoadingReport}
                >
                  Edit
                </IonButton>
              )}
            </div>
          </div>
        </div>

        <div className="report-header-extra-data">
          <div className="subcontent">
            <span>Lab: {isLoadingReport ? <Skeleton size={100} /> : labs[report?.lab as string] || fallback} • </span>
            {isLoadingReport ? (
              <Skeleton size={150} />
            ) : (
              <a href={reportPdfLink as string} target="blank">
                View original report
              </a>
            )}
          </div>

          <div className="content-grid">
            <div>
              <span className="label">SPECIMEN</span>
              {isLoadingReport ? <Skeleton size={150} /> : report?.tumor_specimen || fallback}
            </div>

            <div>
              <span className="label">REPORT DATE</span>
              {isLoadingReport ? <Skeleton size={150} /> : report?.report_date || fallback}
            </div>

            <div>
              <span className="label">SPECIMEN TYPE</span>
              {isLoadingReport ? <Skeleton size={150} /> : report?.specimen_type || fallback}
            </div>

            <div>
              <span className="label">BIOPSY DATE</span>
              {isLoadingReport ? <Skeleton size={150} /> : report?.biopsy_date || fallback}
            </div>
          </div>
        </div>
      </div>

      <IonModal
        ref={modalEdit}
        isOpen={isModalEditOpen}
        onDidDismiss={(info) => {
          if (info.detail.data && info.detail.data.wasEdited) props.onEditHeaderDismiss()
          setModalEditOpen(false)
        }}
      >
        {report?.patient ? (
          <>
            <ModalEditReportHeader
              modal={modalEdit}
              patientsService={props.patientsService}
              headerTitle="Edit cancer details"
              patient={
                {
                  cancer_stage: report?.patient.cancer_stage,
                  cancer_type: report?.patient.cancer_type,
                  status: report?.patient.status,
                  MRN: report?.patient.MRN,
                } as Patient
              }
              fields={{
                cancerType: true,
                stage: true,
                status: true,
                priorTreatments: false,
                payer: false,
                comorbidities: false,
              }}
            />
          </>
        ) : undefined}
      </IonModal>

      <IonModal isOpen={isOpenShare} onDidDismiss={() => setOpenShare(false)}>
        <ModalShareReport
          dismiss={() => setOpenShare(false)}
          usersService={props.userInternalService}
          reportsService={props.reportsService}
          reportId={report?.report_id as string}
        />
      </IonModal>
    </section>
  )
}

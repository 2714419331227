import { IonButton, IonIcon, IonSpinner } from '@ionic/react'
import { addOutline } from 'ionicons/icons'
import { NotFound, Viewport } from 'presentation/components'
import React from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import Pagination from '../../../../components/pagination/pagination'
import { dashboardAtom } from '../../dashboard-atom'
import { headingAtom, headingModalReport } from '../heading/heading-atom'
import PatientsItem from './patients-item/patients-item'
import './patients-list.scss'

interface PatientsListProps {
  search: string
  onGetPage: (pageNumber: number) => void
}

const PatientsList: React.FC<PatientsListProps> = (props) => {
  const state = useRecoilValue(dashboardAtom)
  const headingState = useRecoilValue(headingAtom)
  const setModalReportOpener = useSetRecoilState(headingModalReport)

  const patientsListEmpty = !state.patients || !state.patients.length
  const emptyPatients = !state.isLoadingPatients && patientsListEmpty
  const hasFilter = headingState?.filters && Object.keys(headingState?.filters).length
  const filterNotFound = emptyPatients && (hasFilter || !!props?.search)

  const openNewReport = () => {
    setModalReportOpener((s) => ({ ...s, isOpen: true }))
  }

  return (
    <section className="patients-list">
      <Viewport>
        <table>
          <thead>
            <tr className="patients-list-header">
              <th>Patient</th>
              <th>Cancer Type</th>
              <th>Treatment</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>

          <tbody>
            {state.isLoadingPatients && (
              <tr>
                <td colSpan={5}>
                  <IonSpinner name="dots" />
                </td>
              </tr>
            )}

            {emptyPatients && (
              <tr className="patients-list-item-notfound">
                <td colSpan={5}>
                  {filterNotFound ? (
                    <NotFound>No results for your search</NotFound>
                  ) : (
                    <>
                      <NotFound>You don{"'"}t have patients yet. Start adding them by creating a report</NotFound>
                      <IonButton onClick={openNewReport}>
                        <IonIcon icon={addOutline} slot="start" />
                        New Report
                      </IonButton>
                    </>
                  )}
                </td>
              </tr>
            )}

            {!state.isLoadingPatients &&
              state.patients &&
              state.patients.map((patient, index) => (
                <PatientsItem key={index} index={index} patientItem={patient} onClick={() => null} />
              ))}
          </tbody>
        </table>

        {!state.isLoadingPatients && (state.pageOptions?.quantity as number) > 1 ? (
          <Pagination
            currentPage={state.pageOptions?.current as number}
            quantity={state.pageOptions?.quantity as number}
            onNewPage={props.onGetPage}
          />
        ) : (
          ''
        )}
      </Viewport>
    </section>
  )
}

export default PatientsList

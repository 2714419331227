import { IonIcon } from '@ionic/react'
import React, { useState } from 'react'
import ReactTooltip from 'react-tooltip'
import './action-item.scss'

type ActionItemProps = React.ComponentPropsWithoutRef<typeof IonIcon> & {
  icon: string
  tooltip: string
}

const ActionItem: React.FC<ActionItemProps> = (props) => {
  const [id] = useState(String(props.tooltip + Math.random()))

  return (
    <>
      <IonIcon data-tip={props.tooltip} data-for={id} {...props} className="action-item" />
      <ReactTooltip effect="solid" id={id} />
    </>
  )
}

export default ActionItem

import { IonButton } from '@ionic/react'
import React from 'react'
import './button-round.scss'

type ButtonRoundProps = React.ComponentPropsWithRef<typeof IonButton>

const ButtonRound: React.FC<ButtonRoundProps> = (props: ButtonRoundProps) => {
  return (
    <IonButton size="large" {...props} className="button-round" expand="block">
      {props.children}
    </IonButton>
  )
}

export default ButtonRound

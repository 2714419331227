import { CognitoUserAttribute } from 'amazon-cognito-identity-js'
import { AccessTokenCache } from 'infra/cache/set-access-token-cache'
import { makeRemoteUserPoolCognito } from 'main/factories/http/remote-user-pool-cognito-factory'
import { GetUserInfo, UserInfo } from '../../services/usecases/get-user-info'

export class RemoteGetUserInfoCognito implements GetUserInfo {
  constructor(private accessToken: AccessTokenCache) {}

  private userPool = makeRemoteUserPoolCognito()

  getUserInfo(): Promise<UserInfo> {
    const currentUser = this.userPool.getCurrentUser()

    return new Promise((resolve) => {
      currentUser?.getSession((_: Error, session: any) => {
        this.accessToken.set(session.accessToken.jwtToken)
        currentUser.getUserAttributes((_, attr) => {
          resolve(this.makeAccountObject(attr as CognitoUserAttribute[]))
        })
      })
    })
  }

  makeAccountObject(attrs: CognitoUserAttribute[]): UserInfo {
    type AccountObject = { [key: string]: string }
    type AttributeObject = CognitoUserAttribute | AccountObject
    const account: AccountObject = {}

    const accountHelper = attrs.reduce((_, curr: AttributeObject) => {
      account[curr.Name] = curr.Value

      return account
    }, account)

    return {
      email: accountHelper['email'],
      clinic: accountHelper['custom:clinic'],
      name: accountHelper['custom:name'],
      role: accountHelper['custom:role'],
    }
  }
}

import { ReportExtract } from 'domain/usecases/add-new-report'
import { ConfirmPatientReportParams } from 'domain/usecases/confirm-patient-report'
import { atom } from 'recoil'

export interface ModalNewReportState {
  reportExtract: ReportExtract | null
}

export const modalNewReportAtom = atom<ModalNewReportState>({
  key: 'modalNewReportAtom',
  default: {
    reportExtract: null,
  },
})

export const reportPatientAtom = atom<ConfirmPatientReportParams>({
  key: 'reportPatientAtom',
  default: {
    MRN: '',
    name: '',
    reportId: '',
    nameError: '',
    mrnError: '',
  },
})

import { IonSpinner, useIonToast } from '@ionic/react'
import { ForgotPassword } from 'domain/usecases/forgot-password'
import { route } from 'main/routes'
import { ForgotPasswordValidation } from 'main/validations/usecases/forgot-password-validation'
import {
  Background,
  ButtonLink,
  ButtonRound,
  CardBackground,
  DefaultInput,
  FlexGrid,
  Message,
  Title,
} from 'presentation/components'
import { useSkipFirstRender } from 'presentation/hooks/skip-first-render'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useRecoilState, useResetRecoilState } from 'recoil'
import { forgotPasswordState } from './forgot-password-atom'
import './forgot-password.scss'

type ForgotPasswordPageProps = {
  validations: ForgotPasswordValidation
  auth: ForgotPassword
}

const ForgotPasswordPage: React.FC<ForgotPasswordPageProps> = (props) => {
  const [forgotPasswordGroup, setForgotPasswordGroup] = useRecoilState(forgotPasswordState)
  const [presentSendLinkToast, dismissToast] = useIonToast()
  const resetState = useResetRecoilState(forgotPasswordState)

  const isFirstRender = useSkipFirstRender()
  const history = useHistory()

  useEffect(() => {
    return () => resetState()
  }, [])

  useEffect(() => {
    if (!isFirstRender) {
      const emailError = props.validations.getValidationError(props.validations.email, forgotPasswordGroup.email)

      setForgotPasswordGroup((current) => ({ ...current, emailError }))
    }
  }, [forgotPasswordGroup.email])

  const sendResetLink = () => {
    setForgotPasswordGroup((current) => ({ ...current, isLoading: true }))
    props.auth
      .sendResetLink(forgotPasswordGroup.email)
      .then(() => {
        toastSuccess()
        setTimeout(() => {
          dismissToast()
          history.push(route.resetPassword.url, { email: forgotPasswordGroup.email })
        }, 5000)
      })
      .catch(() => {
        setForgotPasswordGroup((c) => ({ ...c, isEmailValid: false }))
        toastError()
      })
      .finally(() => setForgotPasswordGroup((current) => ({ ...current, isLoading: false })))
  }

  const toastSuccess = () => {
    presentSendLinkToast({
      message: `If there's an account associated with this e-mail address, 
      we'll send you an e-mail with the reset instructions.`.trim(),
      buttons: [
        {
          text: 'Resend',
          handler: () => {
            sendResetLink()
          },
        },
        'Dismiss',
      ],
      color: 'success',
    })
  }

  const toastError = () => {
    presentSendLinkToast({
      message: 'There was an error sending code, try again later.',
      buttons: ['Dismiss'],
      color: 'danger',
      duration: 5000,
    })
  }

  return (
    <Background>
      <CardBackground className="forgot-password">
        <Title size="h1" className="title-center">
          Forgot Password
        </Title>
        <p style={{ textAlign: 'center' }}>Send a link to your email to reset your password</p>

        <form>
          <DefaultInput
            labelText="Email"
            name="email"
            type="email"
            value={forgotPasswordGroup.email}
            state={forgotPasswordGroup}
            setState={setForgotPasswordGroup}
            placeholder="email@email.com"
          />
          {forgotPasswordGroup.emailError && <Message color={'error'}>{forgotPasswordGroup.emailError}</Message>}
          <br />
          <ButtonRound
            onClick={sendResetLink}
            disabled={Boolean(forgotPasswordGroup.emailError || forgotPasswordGroup.isLoading)}
          >
            {!forgotPasswordGroup.isLoading && 'Send Instructions'}
            {forgotPasswordGroup.isLoading && <IonSpinner />}
          </ButtonRound>
          <FlexGrid gap={4} align="center" marginTop={20}>
            <span className="have-account">Already have an account?</span>
            <ButtonLink routerLink={route.login.url}>Log in</ButtonLink>
          </FlexGrid>
        </form>
      </CardBackground>
    </Background>
  )
}

export default ForgotPasswordPage

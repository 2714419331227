import { Validation } from './usecases/validation'

export class StringSizeValidation implements Validation {
  constructor(private min: number, private max: number, private fieldName: string, private message = '') {}

  validate(param: string): string | null {
    const matchesNotEmpty = param && param.length
    const matchesMinSize = Boolean(matchesNotEmpty && param.length >= this.min)
    const matchesMaxSize = Boolean(matchesNotEmpty && param.length <= this.max)

    if (matchesMinSize && matchesMaxSize) return null
    return this.message || `Your ${this.fieldName} should contain between ${this.min} and ${this.max} characters`
  }
}

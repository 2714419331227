import {
  IonButton,
  IonContent,
  IonFooter,
  IonIcon,
  IonItem,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonTextarea,
  IonToolbar,
  useIonToast,
} from '@ionic/react'
import { ReportsService } from 'infra/services/reports-service'
import { UserInternalService } from 'infra/services/user-internal-service'
import { closeOutline, shareSocial } from 'ionicons/icons'
import { ModalTitle, Typeahead } from 'presentation/components'
import Chip from 'presentation/components/chip/chip'
import React, { useEffect, useState } from 'react'
import './modal-share-report.scss'

interface ModalShareReportProps {
  reportsService: ReportsService
  usersService: UserInternalService
  reportId: string
  dismiss: () => void
}

enum SharingTabs {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
  TOPATIENT = 'TOPATIENT',
}

type ShareItem = { label: string; value: string; id: string }

const ModalShareReport: React.FC<ModalShareReportProps> = (props) => {
  const [isSharing, setSharing] = useState(false)
  const [tab] = useState<SharingTabs>(SharingTabs.INTERNAL)
  const [usersList, setUsersList] = useState<ShareItem[]>([])
  const [selectedUsers, setSelectedUsers] = useState<ShareItem[]>([])
  const [shareWith] = useState('')
  const [isLoadingDoctors, setLoadingDoctors] = useState(false)
  const [message, setMessage] = useState('')

  const [presentToast] = useIonToast()

  const share = () => {
    setSharing(true)
    props.reportsService
      .shareReport({
        message: message,
        reportId: props.reportId,
        users: selectedUsers?.map((item) => item.id),
      })
      .then(() => {
        presentToast({
          message: 'Report shared successfully.',
          duration: 5000,
          color: 'success',
        })
        props.dismiss()
      })
      .catch(() => {
        presentToast({
          message: 'An error has occurred; please try again later.',
          duration: 5000,
          color: 'danger',
        })
      })
      .finally(() => {
        setSharing(false)
      })
  }

  const getDoctors = (search: string) => {
    setLoadingDoctors(true)
    props.usersService
      .getUsers({
        page: 1,
        search,
        size: 10,
      })
      .then((response) => {
        const usersToTypeahead: ShareItem[] = response.items?.map((item) => ({
          label: `${item.name} - ${item.email}`,
          value: item.user_id,
          id: item.user_id,
        }))

        setUsersList(usersToTypeahead)
      })
      .catch(() => setUsersList([]))
      .finally(() => setLoadingDoctors(false))
  }

  const addItem = (value: ShareItem, list: ShareItem[]): ShareItem[] => {
    const selecteds: ShareItem[] = JSON.parse(JSON.stringify(list))
    const isAlreadySelected = selecteds.find((item) => item.id === value.id)
    if (!isAlreadySelected) selecteds.push(value)

    return selecteds
  }

  const removeItem = (value: ShareItem, list: ShareItem[]): ShareItem[] => {
    const selecteds: ShareItem[] = JSON.parse(JSON.stringify(list))
    const newList = selecteds.filter((item) => item.id !== value.id)

    return newList
  }

  useEffect(() => {
    getDoctors('')
  }, [])

  return (
    <>
      <IonContent className="ion-padding modal-share-report">
        <ModalTitle icon={shareSocial} label={'Share this report'} />

        <section className="sharing-section">
          <div className="sharing-segments">
            <IonSegment value={tab}>
              <IonSegmentButton value={SharingTabs.INTERNAL}>
                <IonLabel>Internal</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value={SharingTabs.EXTERNAL} disabled>
                <IonLabel>External</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value={SharingTabs.TOPATIENT} disabled>
                <IonLabel>Send to Patient</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </div>

          <div className="sharing-content">
            {selectedUsers?.map((item, key) => (
              <Chip
                key={key}
                onDeleteItem={() => {
                  setSelectedUsers(removeItem(item, selectedUsers))
                }}
              >
                {item.label}
              </Chip>
            ))}

            <div className="share-form-box">
              <Typeahead
                label="Share with"
                placeholder=""
                options={usersList}
                isLoading={isLoadingDoctors}
                value={shareWith}
                onMenuOpen={() => getDoctors('')}
                onTypeaheadDebounce={(value) => getDoctors(value)}
                onChange={(item) => {
                  const selected = item as ShareItem
                  setSelectedUsers(addItem(selected, selectedUsers))
                }}
              />

              <IonItem className="sharing-message">
                <IonLabel position="stacked" color={'medium'}>
                  Message
                </IonLabel>
                <IonTextarea
                  rows={4}
                  maxlength={560}
                  value={message}
                  onIonInput={(e) => setMessage(e.target.value as string)}
                />
                <IonButton fill="clear" slot="end" color={'medium'} onClick={() => setMessage('')}>
                  <IonIcon icon={closeOutline}></IonIcon>
                </IonButton>
              </IonItem>
            </div>
          </div>
        </section>
      </IonContent>

      <IonFooter>
        <IonToolbar>
          <IonButton slot="end" fill="outline" onClick={() => props.dismiss()}>
            Cancel
          </IonButton>

          <IonButton slot="end" fill="solid" onClick={() => share()} disabled={isSharing || !selectedUsers?.length}>
            {isSharing ? <IonSpinner /> : 'INTERNAL SHARE'}
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </>
  )
}

export default ModalShareReport

import { CognitoUser } from 'amazon-cognito-identity-js'
import { makeRemoteUserPoolCognito } from 'main/factories/http/remote-user-pool-cognito-factory'

export class RemoteGetUserCognito {
  private userPool = makeRemoteUserPoolCognito()

  getCognitoUser(username: string) {
    const userData = {
      Username: username,
      Pool: this.userPool,
    }
    const cognitoUser = new CognitoUser(userData)

    return cognitoUser
  }
}

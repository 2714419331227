import React from 'react'
import './not-found.scss'

export const NotFound: React.FC = (props) => {
  return (
    <div className="not-found">
      <img src="assets/not-found.svg" alt="Not Found" />
      <p>{props.children}</p>
    </div>
  )
}

export default NotFound

import PatientPage from 'presentation/pages/patient/patient'
import React from 'react'
import { makePatientsService } from '../services/patients-service-factory'
import { makeReportsService } from '../services/reports-service-factory'
import { makeUserService } from '../services/user-service-factory'

const reportsService = makeReportsService()
const userService = makeUserService()
const patientsService = makePatientsService()

const makePatientPage: React.FC = () => {
  return <PatientPage reportsService={reportsService} userService={userService} patientsService={patientsService} />
}

export default makePatientPage

import { IonButton, IonContent, IonFooter, IonInput, IonItem, IonLabel, IonSpinner, IonToolbar } from '@ionic/react'
import React from 'react'
import { useRecoilState } from 'recoil'
import { modalSelectTreatmentAtom } from './modal-select-treatment-atom'
import './modal-select-treatment.scss'

interface ModalSelectTreamentProps {
  onDismiss: () => void
  onSave: () => void
}

const ModalSelectTreament: React.FC<ModalSelectTreamentProps> = (props) => {
  const [state, setTreatmentsSelection] = useRecoilState(modalSelectTreatmentAtom)

  return (
    <>
      <IonContent className="ion-padding therapies-select">
        <p>
          <b>Choose the treatment you want to recommend to the patient:</b>
        </p>

        <div className="notes-slot">
          <span>
            <b>Add Note</b>
          </span>

          <IonItem>
            <IonLabel position="stacked">Note</IonLabel>
            <IonInput
              placeholder="I choose this treatment because..."
              value={state.notes}
              onIonInput={(e) => setTreatmentsSelection((s: any) => ({ ...s, notes: e.target.value as string }))}
              maxlength={560}
            />
          </IonItem>
        </div>

        <div className="therapies-slot">{props.children}</div>
      </IonContent>

      <IonFooter className="ts-footer">
        <IonToolbar>
          <IonButton slot="end" fill="outline" onClick={props.onDismiss}>
            Cancel
          </IonButton>

          <IonButton slot="end" fill="solid" onClick={props.onSave} disabled={state.isSaving}>
            {state.isSaving ? <IonSpinner /> : 'Save'}
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </>
  )
}

export default ModalSelectTreament

import { IonContent, IonModal, IonPage, IonSpinner } from '@ionic/react'
import { Report, ReportStatus } from 'domain/usecases/get-report'
import { ReportComment } from 'domain/usecases/get-report-comments'
import { GetReportPermissionsResponse, ReportPermissions } from 'domain/usecases/get-report-permissions'
import { InsightsService } from 'infra/services/insights-service'
import { PatientsService } from 'infra/services/patients-service'
import { ReportsService } from 'infra/services/reports-service'
import { UserInternalService } from 'infra/services/user-internal-service'
import { UserService } from 'infra/services/user-service'
import { route } from 'main/routes'
import { ButtonRound, CommentsItem, CustomChip, FlexGrid, Viewport } from 'presentation/components'
import Footer from 'presentation/components/footer/footer'
import { Header } from 'presentation/organisms/header/header'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { useRecoilState } from 'recoil'
import FailedReport from './organisms/failed-report/failed-report'
import GenomicVariants from './organisms/genomic-variants/genomic-variants'
import { ImmunotherapyMarkers } from './organisms/immunotherapy-markers/immunotherapy-markers'
import ModalReplyOncologist from './organisms/modal-reply-oncologist/modal-reply-oncologist'
import { ReportHeader } from './organisms/report-header/report-header'
import TherapiesTrials from './organisms/therapies-trials/therapies-trials'
import { reportAtom } from './report-atom'
import './report.scss'

interface ReportPageProps {
  userService: UserService
  reportsService: ReportsService
  patientsService: PatientsService
  insightsService: InsightsService
  userInternalService: UserInternalService
}

const ReportPage: React.FC<ReportPageProps> = (props) => {
  const [state, setState] = useRecoilState(reportAtom)
  const params = useParams<{ id: string }>()
  const navigate = useHistory()
  const [isViewOnly, setViewOnly] = useState(false)
  const [isModalReplyOpen, setModalReplyOpen] = useState(false)
  const [accessExpired, setAccessExpired] = useState(false)
  const [isLoadingComments, setLoadingComments] = useState(false)
  const [comments, setComments] = useState<ReportComment[]>([])
  const [cachedCancerType, setCachedCancerType] = useState('')

  const getReport = useCallback(() => {
    return new Promise<void>((res, rej) => {
      setState((s) => ({ ...s, isLoadingReport: true }))
      props.reportsService
        .getReport({
          reportId: params.id,
        })
        .then((report) => {
          if (!report.read) markAsRead(report)
          setState({ report })
          getPdfLink()
          res()
        })
        .catch(() => {
          rej()
        })
        .finally(() => {
          setState((s) => ({ ...s, isLoadingReport: false }))
        })
    })
  }, [params.id])

  const getPdfLink = () => {
    props.reportsService
      .getReportPdfLink({ reportId: params.id })
      .then((reportPdfLink) => setState((s) => ({ ...s, reportPdfLink })))
      .catch(() => null)
  }

  useEffect(() => {
    setState((s) => ({
      ...s,
      report: {
        patient: history && history.state && history.state.state,
      } as Report,
    }))
  }, [])

  const markAsRead = (report: Report) => {
    props.reportsService
      .markReportAsread({
        MRN: report.report_id as string,
        read: true,
      })
      .catch(() => null)
  }

  const getGeneInfo = () => {
    setState((s) => ({ ...s, isLoadingGenes: true }))
    const genes: string[] = state.report?.genomic_variants.map((gene) => gene.name) as string[]

    props.insightsService
      .getGenesInfo({ genes })
      .then((response) => setState((s) => ({ ...s, genesInfo: response })))
      .catch(() => null)
      .finally(() => setState((s) => ({ ...s, isLoadingGenes: false })))
  }

  const checkPermissions = () => {
    return new Promise<GetReportPermissionsResponse>((resolve, reject) => {
      props.reportsService
        .getReportPermissions({
          reportId: params.id,
        })
        .then((res) => {
          resolve(res)
        })
        .catch(() => {
          setAccessExpired(true)
          reject()
        })
    })
  }

  const getReportComments = () => {
    setLoadingComments(true)
    props.reportsService
      .getReportComments({
        page: 1,
        reportId: params.id,
        size: 1000,
      })
      .catch(() => null)
      .then((response) => setComments(response?.items as ReportComment[]))
      .finally(() => setLoadingComments(false))
  }

  const checkViewType = (permissions: ReportPermissions[]) => {
    if (!permissions.includes('report:write')) setViewOnly(true)
  }

  const updateCachedValue = (cachedValue: string) => {
    if (cachedValue && cachedValue.length) setCachedCancerType(cachedValue)
  }

  useEffect(() => {
    if (params.id) {
      checkPermissions()
        .then((data) => {
          checkViewType(data.permissions)
          getReport().catch(() => navigate.replace(route.dashboard.url))
          getReportComments()
        })
        .catch(() => setAccessExpired(true))
    }
  }, [params.id])

  useEffect(() => {
    updateCachedValue(state?.report?.patient?.cancer_type as string)
    if (state.report && state.report.report_id !== undefined && !state.genesInfo) getGeneInfo()
  }, [state.report])

  return (
    <IonPage>
      <Header userService={props.userService} tab={'patients'} injectedQuery={cachedCancerType} />
      <IonContent>
        <Viewport className="report-page-viewport">
          {accessExpired ? (
            <FailedReport
              title="Error"
              body={<>This link has expired; to access the report, you must request a new link from the owner.</>}
            />
          ) : (
            <>
              {state.report?.status === ReportStatus.failed ? (
                <FailedReport
                  title="This report couldn\'t be processed"
                  body={
                    <>
                      Our system had a problem while trying to process this report. <br />
                      Please try uploading it again or{' '}
                      <a href="mailto:support@oncorxinsights.com">contact our support team</a>
                    </>
                  }
                />
              ) : (
                <>
                  {!state.isLoadingReport && state.report?.status === ReportStatus.processing && (
                    <CustomChip color="warning">
                      This report is still being processed. Please reload it in a moment or two
                      <span className="underscore-text" onClick={getReport}>
                        {' '}
                        Reload Now
                      </span>
                    </CustomChip>
                  )}
                  <ReportHeader
                    patientsService={props.patientsService}
                    onEditHeaderDismiss={getReport}
                    userInternalService={props.userInternalService}
                    reportsService={props.reportsService}
                    isViewOnly={isViewOnly}
                  />
                  <GenomicVariants />
                  <ImmunotherapyMarkers
                    options={{
                      microsatellite_instability_status: state.report?.microsatellite_instability_status,
                      tumor_mutational_burden: state.report?.tumor_mutational_burden,
                      tmb_percentile: state.report?.tmb_percentile,
                    }}
                  />
                  <TherapiesTrials
                    reportsService={props.reportsService}
                    onTreatmentSaved={getReport}
                    isViewOnly={isViewOnly}
                  />
                </>
              )}
            </>
          )}

          {isLoadingComments ? (
            <FlexGrid justifyContent={'center'}>
              <IonSpinner name="dots" />
            </FlexGrid>
          ) : (
            <>
              {comments?.map((comment, key) => (
                <CommentsItem key={key} note={comment.comment} created={comment.created} fromText={comment.user_name} />
              ))}
            </>
          )}
        </Viewport>
      </IonContent>

      <IonModal
        isOpen={isModalReplyOpen}
        onDidDismiss={() => setModalReplyOpen(false)}
        className="modal-reply-oncologist"
      >
        <ModalReplyOncologist
          report={state?.report as Report}
          dismiss={(data) => {
            setModalReplyOpen(false)
            if (data && data.wasSaved) getReportComments()
          }}
          reportsService={props.reportsService}
        />
      </IonModal>

      <Footer
        slot={
          isViewOnly ? (
            <div className="reply-to-oncologist">
              <ButtonRound size="small" onClick={() => setModalReplyOpen(true)}>
                Reply to {state.report?.doctor_name}
              </ButtonRound>
            </div>
          ) : undefined
        }
      />
    </IonPage>
  )
}

export default ReportPage

import React from 'react'
import './message.scss'

type MessageProps = {
  color: 'success' | 'warning' | 'error'
}

const Message: React.FC<MessageProps> = (props) => {
  return <div className={`message-span ${props.color}`}>{props.children}</div>
}

export default Message

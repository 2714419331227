import { useIonToast } from '@ionic/react'
import { ConfirmPatientReportParams } from 'domain/usecases/confirm-patient-report'
import { Patient } from 'domain/usecases/get-patient'
import { PatientsService } from 'infra/services/patients-service'
import { ReportsService } from 'infra/services/reports-service'
import { ButtonRound, Viewport } from 'presentation/components'
import { ModalNewReport } from 'presentation/pages/modal-new-report/modal-new-report'
import React, { useRef, useState } from 'react'
import './patient-heading.scss'

interface PatientHeadingProps {
  reportsService: ReportsService
  patientsService: PatientsService
  patientItem: Patient
}

const PatientHeading: React.FC<PatientHeadingProps> = (props) => {
  const modalNewReport = useRef<HTMLIonModalElement>(null)

  const [isModalReportOpen, setModalReportOpen] = useState<boolean>(false)
  const [presentToast] = useIonToast()

  const handleConfirmPatient = (confirmPatient: ConfirmPatientReportParams) => {
    if (props.patientItem.MRN === confirmPatient.MRN) {
      props.reportsService
        .confirmPatientReport(confirmPatient)
        .then(() => setModalReportOpen(false))
        .catch(() => showToastError('There was an error trying to confirm patient, try again later.'))
    } else {
      showToastError('Report MRN and Patient MRN does not match')
    }
  }

  const showToastError = (message: string) => {
    presentToast({
      message: message,
      duration: 5000,
      color: 'danger',
    })
  }

  return (
    <section id="patient-heading">
      <Viewport>
        <div className="ph-background">
          <div className="ph-info">
            <div className="ph-chip">MRN: {props.patientItem.MRN}</div>
            <h1>{props.patientItem.name}</h1>
            <span>
              Birth: {props.patientItem.birthdate} - {props.patientItem.age}yo / Sex: {props.patientItem.sex}
            </span>
          </div>
          <div>
            <ButtonRound size="small" color={'light'} onClick={() => setModalReportOpen(true)}>
              + New Report
            </ButtonRound>
          </div>
        </div>
      </Viewport>

      <ModalNewReport
        trigger="trigger-modal-report"
        modal={modalNewReport}
        isOpen={isModalReportOpen}
        reportsService={props.reportsService}
        patientsService={props.patientsService}
        notNeededToConfirm={true}
        onUnconfirmedPatient={(confirm) => handleConfirmPatient(confirm)}
        onDidDismiss={() => setModalReportOpen(false)}
      />
    </section>
  )
}

export default PatientHeading

import { ForgotPasswordPage } from 'presentation/pages'
import React from 'react'
import { makeForgotPasswordValidations } from '../builders/forgot-password-validation-factory'
import { makeForgotPasswordService } from '../services/forgot-password-service-factory'

const makeForgotPasswordPage: React.FC = () => {
  return <ForgotPasswordPage validations={makeForgotPasswordValidations()} auth={makeForgotPasswordService()} />
}

export default makeForgotPasswordPage

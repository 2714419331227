import { IonSpinner, useIonToast } from '@ionic/react'
import { ResetPassword } from 'domain/usecases/reset-password'
import { route } from 'main/routes'
import { ResetPasswordValidations } from 'main/validations/usecases/reset-password-validation'
import {
  Background,
  ButtonRound,
  CardBackground,
  DefaultInput,
  Message,
  PasswordInput,
  Title,
} from 'presentation/components'
import { useSkipFirstRender } from 'presentation/hooks/skip-first-render'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useRecoilState, useResetRecoilState } from 'recoil'
import { resetPasswordState } from './reset-password-atom'

type ResetPasswordProps = {
  validations: ResetPasswordValidations
  resetPassword: ResetPassword
}

const ResetPasswordPage: React.FC<ResetPasswordProps> = (props) => {
  const [resetPasswordGroup, setResetPasswordGroup] = useRecoilState(resetPasswordState)
  const isFirstRender = useSkipFirstRender()
  const [presetToast, dismissToast] = useIonToast()
  const navigate = useHistory()
  const resetState = useResetRecoilState(resetPasswordState)

  useEffect(() => {
    if (!isFirstRender) {
      const passwordError = props.validations.getValidationError(
        props.validations.password,
        resetPasswordGroup.password
      )

      setResetPasswordGroup((current) => ({ ...current, passwordError }))
    }
  }, [resetPasswordGroup.password])

  useEffect(() => {
    return () => resetState()
  }, [])

  const sendResetLink = () => {
    if (history.state?.state) {
      setResetPasswordGroup((current) => ({ ...current, isLoading: true }))
      props.resetPassword
        .verifyCode(history.state.state.email, resetPasswordGroup.code, resetPasswordGroup.password)
        .then(() => {
          setResetPasswordGroup((current) => ({ ...current, remoteError: '' }))
          presetToast({
            message: 'Password Saved. We will redirect you to Log in screen in 5s',
            color: 'success',
            buttons: [{ text: 'LOGIN', handler: () => navigate.push(route.login.url) }],
          })
          setTimeout(() => {
            dismissToast()
            navigate.push(route.login.url)
          }, 5000)
        })
        .catch(() => {
          setResetPasswordGroup((current) => ({
            ...current,
            remoteError: 'There was an error to validate, please try again',
          }))
        })
        .finally(() => setResetPasswordGroup((current) => ({ ...current, isLoading: false })))
    } else {
      presetToast({
        message: 'There was a problem with resetting your password. Please try again soon',
        color: 'danger',
        duration: 5000,
      })
    }
  }

  const isSubmitDisabled = () => {
    const isValid = Boolean(
      !!resetPasswordGroup.code && !resetPasswordGroup.passwordError && !resetPasswordGroup.isLoading
    )

    return !isValid
  }

  return (
    <Background>
      <CardBackground className="reset-password">
        <Title size="h1" className="title-center">
          Reset Password
        </Title>
        <p style={{ textAlign: 'center' }}>Please choose your new password</p>

        <form>
          <DefaultInput
            labelText="Verification Code"
            name="code"
            type="text"
            value={resetPasswordGroup.code}
            state={resetPasswordGroup}
            setState={setResetPasswordGroup}
            placeholder="123456"
          />
          <PasswordInput
            labelText="Password"
            name="password"
            type="password"
            value={resetPasswordGroup.password}
            state={resetPasswordGroup}
            setState={setResetPasswordGroup}
            placeholder="E.g. Passw0rd?"
          />
          {resetPasswordGroup.passwordError && <Message color="error">{resetPasswordGroup.passwordError}</Message>}
          <br />
          {resetPasswordGroup.remoteError && <Message color={'error'}>{resetPasswordGroup.remoteError}</Message>}
          <ButtonRound onClick={sendResetLink} disabled={isSubmitDisabled()}>
            {!resetPasswordGroup.isLoading && 'Save New Password'}
            {resetPasswordGroup.isLoading && <IonSpinner />}
          </ButtonRound>
        </form>
      </CardBackground>
    </Background>
  )
}

export default ResetPasswordPage

import { IonButton, IonContent, IonFooter, IonInput, IonModal, IonSpinner, IonToolbar, useIonToast } from '@ionic/react'
import { Patient } from 'domain/usecases/get-patient'
import { PatientStatus } from 'domain/usecases/list-patients'
import { PatientsService } from 'infra/services/patients-service'
import { pencil } from 'ionicons/icons'
import { ModalTitle, Typeahead } from 'presentation/components'
import { GroupedOption, TypeaheadItem } from 'presentation/components/typeahead/typeahead'
import React, { useCallback, useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { reportToTypeaheadItem } from '../../report-atom'
import { modalEditReportHeaderAtom, ModalEditReportHeaderState } from './modal-edit-report-header-atom'
import './modal-edit-report-header.scss'

type ModalEditReportHeaderProps = React.ComponentPropsWithoutRef<typeof IonModal> & {
  modal: React.RefObject<HTMLIonModalElement>
  patientsService: PatientsService
  patient: Patient
  fields: {
    cancerType: boolean
    stage: boolean
    status: boolean
    priorTreatments: boolean
    payer: boolean
    comorbidities: boolean
  }
  headerTitle: string
}

const ModalEditReportHeader: React.FC<ModalEditReportHeaderProps> = (props) => {
  const [editState, setEditState] = useRecoilState(modalEditReportHeaderAtom)
  const [isSaving, setSaving] = useState(false)

  const [cancerTypes, setCancerTypes] = useState<GroupedOption[]>([])
  const [isLoadingCancerTypes, setLoadingCancerTypes] = useState(false)

  const [payers, setPayers] = useState<TypeaheadItem[]>([])
  const [isLoadingPayers, setLoadingPayers] = useState(false)

  const [stages, setStages] = useState<TypeaheadItem[]>([])

  const makeTypeahead = (label: string, value: number | string) => ({ value, label })
  const status = [makeTypeahead('Responding', 'responding'), makeTypeahead('Progressing', 'progressing')]

  const [presentToast] = useIonToast()

  const getCancerTypes = () => {
    setLoadingCancerTypes(true)
    props.patientsService
      .getCancerTypes({ from_reports: false })
      .then((result) => {
        if (result) {
          const newItens: GroupedOption[] = result.map((item) => ({ label: item.category, options: item.options }))
          setCancerTypes(newItens)
        } else setCancerTypes([])
      })
      .catch(() => setCancerTypes([]))
      .finally(() => setLoadingCancerTypes(false))
  }

  const getPayers = (adapter: string) => {
    setLoadingPayers(true)

    props.patientsService
      .getPayers({
        page: 1,
        search: adapter || '',
        size: 10,
      })
      .then((result) => {
        if (result) {
          const newItens: TypeaheadItem[] = result.items.map((item) => ({
            label: item,
            value: item,
          }))
          setPayers(newItens)
        } else setCancerTypes([])
      })
      .catch(() => setCancerTypes([]))
      .finally(() => setLoadingPayers(false))
  }

  const handleDebounceFn = useCallback((value: { [key: string]: string }) => {
    const adapter: { key: keyof ModalEditReportHeaderState; value: string } = {
      key: Object.keys(value)[0] as keyof ModalEditReportHeaderState,
      value: Object.values(value)[0],
    }

    switch (adapter.key) {
      case 'cancerType':
        getCancerTypes()
        break
      case 'payer':
        getPayers(adapter.value)
        break
      default:
        break
    }
  }, [])

  const getStages = () => {
    props.patientsService
      .getStages()
      .then((stages) => setStages(stages))
      .catch(() => setStages([]))
  }

  const save = () => {
    setSaving(true)
    props.patientsService
      .editPatient({
        cancer_stage: editState.stages?.value as string,
        cancer_type: editState.cancerType?.value as string,
        status: editState.status?.value as PatientStatus,
        prior_treatments: editState.priorTreatments as string,
        payer: editState.payer?.value as string,
        co_morbidities: editState.comorbidities as string,
        mrn: props.patient.MRN as string,
      })
      .then(() => props.modal.current?.dismiss({ wasEdited: true }))
      .catch((message) => {
        presentToast({
          message,
          duration: 5000,
          color: 'danger',
        })
      })
      .finally(() => setSaving(false))
  }

  useEffect(() => {
    setEditState(() => reportToTypeaheadItem(props.patient) as ModalEditReportHeaderState)
  }, [props.patient])

  useEffect(() => {
    getStages()
  }, [])

  return (
    <>
      <IonContent className="ion-padding modal-edit-report-header">
        <ModalTitle icon={pencil} label={props.headerTitle} />

        {props.fields.cancerType ? (
          <Typeahead
            label="Cancer Type"
            placeholder="Select..."
            options={cancerTypes}
            isLoading={isLoadingCancerTypes}
            onMenuOpen={() => handleDebounceFn({ cancerType: '' })}
            onTypeaheadDebounce={(cancerType) => handleDebounceFn({ cancerType })}
            onChange={(cancerType) => setEditState((s) => ({ ...s, cancerType } as ModalEditReportHeaderState))}
            value={editState.cancerType}
          />
        ) : undefined}

        {props.fields.stage ? (
          <Typeahead
            label="Stage"
            placeholder="Select..."
            options={stages}
            onTypeaheadDebounce={() => null}
            onChange={(stages) => setEditState((s) => ({ ...s, stages } as ModalEditReportHeaderState))}
            value={editState.stages}
          />
        ) : undefined}

        {props.fields.status ? (
          <Typeahead
            label="Status"
            placeholder="Select..."
            options={status}
            onTypeaheadDebounce={(status) => handleDebounceFn({ status })}
            onChange={(status) => setEditState((s) => ({ ...s, status } as ModalEditReportHeaderState))}
            value={editState.status}
          />
        ) : undefined}

        {props.fields.priorTreatments ? (
          <div className="free-text-input">
            <span>Prior Treatments</span>
            <IonInput
              value={editState.priorTreatments}
              onIonInput={(e) =>
                setEditState((s) => ({ ...s, priorTreatments: e.target.value } as ModalEditReportHeaderState))
              }
              placeholder="-"
            />
          </div>
        ) : undefined}

        {props.fields.payer ? (
          <Typeahead
            label="Payer"
            placeholder="Unknown"
            options={payers}
            isLoading={isLoadingPayers}
            onMenuOpen={() => handleDebounceFn({ payer: '' })}
            onTypeaheadDebounce={(payer) => {
              handleDebounceFn({ payer })
            }}
            onChange={(payer) => setEditState((s) => ({ ...s, payer } as ModalEditReportHeaderState))}
            value={editState.payer}
          />
        ) : undefined}

        {props.fields.comorbidities ? (
          <div className="free-text-input">
            <span>Co-morbidities</span>
            <IonInput
              value={editState.comorbidities}
              onIonInput={(e) =>
                setEditState((s) => ({ ...s, comorbidities: e.target.value } as ModalEditReportHeaderState))
              }
              placeholder="-"
            />
          </div>
        ) : undefined}
      </IonContent>

      <IonFooter>
        <IonToolbar>
          <IonButton slot="end" fill="outline" onClick={() => props.modal.current?.dismiss({ wasEdited: false })}>
            Cancel
          </IonButton>

          <IonButton slot="end" fill="solid" onClick={() => save()} disabled={isSaving}>
            {isSaving ? <IonSpinner /> : 'Save'}
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </>
  )
}

export default ModalEditReportHeader

import { Validation } from './usecases/validation'

export class PasswordValidation implements Validation {
  private passwordValidationRegex = new RegExp(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`])\S{8,256}$/
  )

  validate(param: string): string | null {
    if (this.passwordValidationRegex.test(param)) return null
    return `The password needs to have at least 8 characters in length with a combination of
    letters and numbers, use upper and lower case letters, and contain a special character`
  }
}

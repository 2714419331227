import { GeneInfoResponse } from 'domain/usecases/get-genes-info'
import { Patient } from 'domain/usecases/get-patient'
import { Report } from 'domain/usecases/get-report'
import { PatientStatus, toStatus } from 'domain/usecases/list-patients'
import { atom } from 'recoil'
import { ModalEditReportHeaderState } from './organisms/modal-edit-report-header/modal-edit-report-header-atom'

interface ReportPageState {
  report: Report | null
  reportPdfLink?: string | null
  isLoadingReport?: boolean | null
  isLoadingGenes?: boolean | null
  genesInfo?: GeneInfoResponse | null
}

export const reportAtom = atom<ReportPageState>({
  key: 'reportAtom',
  default: {
    report: null,
    reportPdfLink: null,
    isLoadingReport: true,
    genesInfo: null,
    isLoadingGenes: null,
  },
})

export const reportToTypeaheadItem = (patient: Patient) => {
  return {
    cancerType: {
      label: patient?.cancer_type as string,
      value: patient?.cancer_type as string,
    },
    stages: {
      label: patient?.cancer_stage as string,
      value: patient?.cancer_stage as string,
    },
    status: {
      label: toStatus[patient?.status as PatientStatus] as string,
      value: patient?.status as string,
    },
    comorbidities: patient.co_morbidities,
    payer: {
      label: patient?.payer as string,
      value: patient?.payer as string,
    },
    priorTreatments: patient.prior_treatments,
  } as ModalEditReportHeaderState
}

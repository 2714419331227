import { atom } from 'recoil'

export type LoginFormState = {
  email: string
  emailError: string | null
  password: string
  remoteError: string
  isLoading: boolean
}

export const loginFormState = atom<LoginFormState>({
  key: 'loginFormState',
  default: {
    email: '',
    emailError: null,
    password: '',
    remoteError: '',
    isLoading: false,
  },
})

import { IonIcon } from '@ionic/react'
import { openOutline } from 'ionicons/icons'
import { Viewport } from 'presentation/components'
import React from 'react'
import './explore-tabs.scss'

export type ExploreTabsTypes = 'pubmed' | 'clinical-trials' | 'asco'

interface ExploreTabsProps {
  tab: ExploreTabsTypes
  onTabChange: (tab: ExploreTabsTypes) => void
  onOpenClick?: (tab: ExploreTabsTypes) => void
}

const ExploreTabs: React.FC<ExploreTabsProps> = (props) => {
  const onTabChange = (tab: ExploreTabsTypes) => {
    props.onTabChange(tab)
  }

  return (
    <section className="explore-tabs">
      <Viewport>
        <div className="explore-tabs-viewport">
          <div className={`tab ${props.tab === 'pubmed' ? 'selected-tab' : ''}`} onClick={() => onTabChange('pubmed')}>
            PubMed
          </div>
          <div
            className={`tab ${props.tab === 'clinical-trials' ? 'selected-tab' : ''}`}
            onClick={() => onTabChange('clinical-trials')}
          >
            ClinicalTrials.gov
          </div>
          <div
            className={`tab ${props.tab === 'asco' ? 'selected-tab' : ''}`}
            onClick={() => props.onOpenClick && props.onOpenClick('asco')}
          >
            <span className="tab-chip">
              ASCO <IonIcon icon={openOutline} />
            </span>
          </div>
        </div>
      </Viewport>
    </section>
  )
}

export default ExploreTabs

import { AccessTokenCache } from 'infra/cache/set-access-token-cache'
import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { route } from '.'

type PublicRouteProps = RouteProps & {
  accessToken: AccessTokenCache
}

const PublicRoute: React.FC<PublicRouteProps> = (props) => {
  const accessToken = props.accessToken.get()

  return accessToken && accessToken.length ? <Redirect to={route.dashboard.url} /> : <Route {...props} />
}

export default PublicRoute

import { Treatment } from 'domain/usecases/get-report'
import { atom } from 'recoil'

export interface ModalSelectTreatmentState {
  isSaving: boolean
  treatments: Treatment[]
  notes: string
}

export const modalSelectTreatmentAtom = atom<ModalSelectTreatmentState>({
  key: 'modalSelectTreatmentAtom',
  default: {
    isSaving: false,
    treatments: [],
    notes: '',
  },
})

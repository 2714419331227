import { clinicsUrl } from 'infra/config/aws-env'
import { HttpClient } from 'infra/http/usecases/http-client'
import { GenericSelector } from './usecases/generic-selector'
import { GetClinics } from './usecases/get-clinics'

export class ClinicsService implements GetClinics {
  constructor(private baseUrl: string, private httpClient: HttpClient) {}

  getClinics(): Promise<GenericSelector[]> {
    return this.httpClient
      .request({
        headers: {
          'Content-Type': 'application/json',
        },
        type: 'get',
        url: `${this.baseUrl}${clinicsUrl.getClinics}`,
      })
      .then((clinicsList) => clinicsList)
      .catch((error) => error)
  }
}

/* eslint-disable indent */
import { AddNewNote, AddNewNoteParams, AddNewNoteResponse } from 'domain/usecases/add-new-note'
import { EditPatient, EditPatientProps } from 'domain/usecases/edit-patient'
import { GetPatient, GetPatientParams, Patient } from 'domain/usecases/get-patient'
import { GetPayers, GetPayersParams, GetPayersResponse } from 'domain/usecases/get-payers'
import { GetStages } from 'domain/usecases/get-stages'
import { ListPatients, ListPatientsParams, PatientItem, PatientsItemDto } from 'domain/usecases/list-patients'
import { stringToTypeaheadItem } from 'helpers/string-to-typeahead'
import { baseUrl, entitiesUrl, patientsUrl } from 'infra/config/aws-env'
import { HttpClient } from 'infra/http/usecases/http-client'
import { TypeaheadItem } from 'presentation/components/typeahead/typeahead'
import { CancerType, GetCancerTypes, GetCancerTypesProps } from './usecases/get-cancer-types'
import { GetGenomicVariants, GetGenomicVariantsParams } from './usecases/get-genomic-variants'
import { GetTreatments } from './usecases/get-treatments'

export class PatientsService
  implements
    ListPatients,
    GetGenomicVariants,
    GetCancerTypes,
    GetTreatments,
    GetStages,
    EditPatient,
    GetPatient,
    GetPayers,
    AddNewNote
{
  constructor(private readonly http: HttpClient) {}

  getPatients(params: Partial<ListPatientsParams>): Promise<PatientsItemDto> {
    return new Promise((resolve, reject) => {
      this.http
        .request({
          headers: {
            'Content-Type': 'application/json',
          },
          type: 'post',
          url: `${baseUrl}${patientsUrl.base}${this.getQueryString(params)}`,
          body: params,
        })
        .then((result) => resolve(result))
        .catch(() => reject())
    })
  }

  private getQueryString(params: Partial<ListPatientsParams>) {
    return `?page=${params.page}&size=${params.size}`
  }

  getGenomicVariants(params: GetGenomicVariantsParams): Promise<TypeaheadItem[]> {
    return new Promise((resolve) => {
      this.http
        .request({
          headers: {
            'Content-Type': 'application/json',
          },
          type: 'get',
          url: `${baseUrl}${entitiesUrl.base}/genes?page=${params.page}&search=${params.search}&size=${params.size}`,
        })
        .then((result) => {
          resolve(stringToTypeaheadItem(result && result.items))
        })
        .catch(() => resolve([]))
    })
  }

  getCancerTypes(params: GetCancerTypesProps): Promise<CancerType[]> {
    return new Promise((resolve, reject) => {
      this.http
        .request({
          headers: {
            'Content-Type': 'application/json',
          },
          type: 'get',
          url: `${baseUrl}${entitiesUrl.base}/cancer-types?from_reports=${params.from_reports}`,
        })
        .then((result) => {
          if (Array.isArray(result)) {
            const newResult = result.map((item) => {
              item.options = item.options.map((option: string) => ({ label: option, value: option }))

              return item
            })

            resolve(newResult)
          }
        })
        .catch(() => reject())
    })
  }

  getTreatments(): Promise<string[]> {
    return new Promise((resolve, reject) => {
      this.http
        .request({
          headers: {
            'Content-Type': 'application/json',
          },
          type: 'get',
          url: `${baseUrl}${entitiesUrl.base}/treatments`,
        })
        .then((result) => {
          resolve(result)
        })
        .catch(() => reject())
    })
  }

  getStages(): Promise<TypeaheadItem[]> {
    return new Promise((resolve) => {
      this.http
        .request({
          headers: {
            'Content-Type': 'application/json',
          },
          type: 'get',
          url: `${baseUrl}${entitiesUrl.base}/cancer-stages`,
        })
        .then((stages) => {
          if (Array.isArray(stages)) {
            const newStages = stages.map((stage) => ({ label: stage, value: stage }))

            resolve(newStages)
          }

          resolve([])
        })
        .catch(() => resolve([]))
    })
  }

  editPatient(props: EditPatientProps): Promise<PatientItem> {
    return new Promise((resolve, reject) => {
      this.http
        .request({
          type: 'patch',
          headers: {
            'Content-Type': 'application/json',
          },
          url: `${baseUrl}${patientsUrl.base}/${props.mrn}`,
          body: props,
        })
        .then((patient) => resolve(patient))
        .catch((error) => {
          const getError = error && error.detail && error.detail[0]

          reject(getError.msg)
        })
    })
  }

  getPatient(props: GetPatientParams): Promise<Patient> {
    return new Promise((resolve, reject) => {
      this.http
        .request({
          type: 'get',
          headers: {},
          url: `${baseUrl}${patientsUrl.base}/${props.mrn}`,
        })
        .then((patient) => resolve(patient))
        .catch((error) => {
          const getError = error && error.detail && error.detail[0]

          reject(getError.msg)
        })
    })
  }

  getPayers(params: GetPayersParams): Promise<GetPayersResponse> {
    return new Promise((resolve, reject) => {
      this.http
        .request({
          type: 'get',
          headers: {},
          url: `${baseUrl}${entitiesUrl.base}/payers?page=${params.page}&search=${params.search}&size=${params.size}`,
        })
        .then((patient) => resolve(patient))
        .catch((error) => {
          const getError = error && error.detail && error.detail[0]

          reject(getError.msg)
        })
    })
  }

  addNewNote(params: AddNewNoteParams): Promise<AddNewNoteResponse> {
    return new Promise((resolve, reject) => {
      this.http
        .request({
          headers: {
            'Content-Type': 'application/json',
          },
          type: 'post',
          url: `${baseUrl}${patientsUrl.base}/${params.MRN}/notes`,
          body: {
            note: params.note,
          },
        })
        .then((result) => resolve(result))
        .catch(() => reject())
    })
  }
}

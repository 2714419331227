import React from 'react'
import './relevance-item.scss'

type Relevance = 'High' | 'Medium' | 'Low'
type RelevanceItemProps = {
  relevance: Relevance | null | string
}

export const RelevanceItem: React.FC<RelevanceItemProps> = (props) => {
  return <div className={`relevance-item ${props.relevance}`}>{props.relevance}</div>
}

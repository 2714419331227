import { TypeaheadItem } from 'presentation/components/typeahead/typeahead'
import { atom } from 'recoil'

export type HeadingFilter = Partial<{
  patient: TypeaheadItem
  cancerType: TypeaheadItem
  treatment: TypeaheadItem
  status: TypeaheadItem
  genomicVariation: TypeaheadItem
  dates: TypeaheadItem
}>

export type HeadingState = {
  search: string
  filters: HeadingFilter
  orderBy: string
  tokens: string[]
} | null

export const headingAtom = atom<HeadingState>({
  key: 'headingAtom',
  default: {
    search: '',
    orderBy: 'changed',
    tokens: [],
    filters: {} as HeadingFilter,
  },
})

export const headingModalReport = atom({
  key: 'headingModalReport',
  default: {
    isOpen: false,
  },
})

import { IonButton, IonContent, IonIcon, IonLabel, IonPage } from '@ionic/react'
import { GetExploreResultsParamsOrderBy } from 'domain/usecases/get-explore-results'
import { ListPatientsParams } from 'domain/usecases/list-patients'
import { PatientsService } from 'infra/services/patients-service'
import { ReportsService } from 'infra/services/reports-service'
import { UserService } from 'infra/services/user-service'
import { addOutline } from 'ionicons/icons'
import { DebounceInput } from 'presentation/components'
import Footer from 'presentation/components/footer/footer'
import ExploreHeading from 'presentation/organisms/explore-heading/explore-heading'
import { Header } from 'presentation/organisms/header/header'
import React, { useEffect, useRef, useState } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { ModalNewReport } from '../modal-new-report/modal-new-report'
import { dashboardAtom } from './dashboard-atom'
import './dashboard.scss'
import { HeadingFilter, headingModalReport } from './organisms/heading/heading-atom'
import ModalFilterStatePatients from './organisms/modal-filter-patients/modal-filter-patients'
import PatientsList from './organisms/patients-list/patients-list'

interface DashboardPageProps {
  userService: UserService
  patientsService: PatientsService
  reportsService: ReportsService
}

const DashboardPage: React.FC<DashboardPageProps> = (props) => {
  const setState = useSetRecoilState(dashboardAtom)
  const filter = {
    list: {},
  }
  const modalNewReport = useRef<HTMLIonModalElement>(null)
  const [modalReportOpener, setModalReportOpener] = useRecoilState(headingModalReport)
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const modalFilterPatients = useRef<HTMLIonModalElement>(null)

  const [orderBy, setOrderBy] = useState('changed')
  const [search, setSearch] = useState('')
  const [chips, setChips] = useState<HeadingFilter>({})

  const handleDeleteChip = (chip: string) => {
    const currentChips = JSON.parse(JSON.stringify(chips))

    delete currentChips[chip]

    setChips(currentChips)
  }

  const getPage = (params: { pageNumber: number }) => {
    setState((s) => ({ ...s, isLoadingPatients: true }))

    props.patientsService
      .getPatients({
        page: params.pageNumber,
        size: 10,
        ...filter.list,
      })
      .then((page) => {
        setState((s) => ({
          ...s,
          patients: page.items,
          pageOptions: {
            current: params.pageNumber,
            quantity: Math.ceil(page.total / page.size),
            size: page.total,
          },
        }))
      })
      .catch(() => setState((s) => ({ ...s, patients: [] })))
      .finally(() => setState((s) => ({ ...s, isLoadingPatients: false })))
  }

  const onFilterChange = () => {
    filter.list = {
      search: search,
      ...(chips?.cancerType ? { cancer_type: [chips?.cancerType.label] } : {}),
      ...(chips.genomicVariation ? { genomic_variant: [chips.genomicVariation.label] } : {}),
      ...(chips.status ? { status: [chips.status.value] } : {}),
      ...(chips.treatment ? { treatment: [chips.treatment.value] } : {}),
      ...(chips.patient ? { name: chips.patient.label } : {}),
      order_by: {
        field: orderBy || 'changed',
        order: orderBy === 'changed' ? 'DESC' : 'ASC',
      },
    } as Partial<ListPatientsParams>

    getPage({ pageNumber: 1 })
  }

  useEffect(() => {
    onFilterChange()
  }, [search, chips, orderBy])

  return (
    <IonPage className="dashboard-wrapper">
      <Header userService={props.userService} tab="patients" />
      <IonContent>
        <ExploreHeading
          headingTitle={'Patients'}
          onFilterButtonClick={() => setIsFilterOpen(true)}
          slotSearch={
            <>
              <IonLabel position="stacked">Search</IonLabel>
              <DebounceInput
                value={search}
                debounceTime={500}
                onDebounceChange={(search) => setSearch(search)}
                placeholder="Type a Name or an MRN to filter the list"
              />
            </>
          }
          slotAction={
            <IonButton
              color="light"
              id="trigger-modal-report"
              onClick={() => setModalReportOpener((s) => ({ ...s, isOpen: true }))}
            >
              <IonIcon icon={addOutline} slot="start" />
              New Report
            </IonButton>
          }
          slotSearchHide={false}
          onDeleteSearch={() => setSearch('')}
          //
          tokens={[]}
          onDeleteToken={() => null}
          onClearTokens={() => null}
          //
          chips={chips}
          onDeleteChip={(chip) => handleDeleteChip(chip)}
          onClearAll={() => setChips({})}
          //
          orderByOptions={[
            { label: 'Patient Name', value: 'name' },
            { label: 'Last Update', value: 'changed' },
          ]}
          orderByValue={orderBy}
          orderByPlaceHolder={'Select'}
          onOrderByValueChange={(value) => setOrderBy(value as GetExploreResultsParamsOrderBy)}
        />
        <PatientsList onGetPage={(n) => getPage({ pageNumber: n })} search={search} />

        <ModalNewReport
          trigger="trigger-modal-report"
          modal={modalNewReport}
          isOpen={modalReportOpener.isOpen}
          reportsService={props.reportsService}
          patientsService={props.patientsService}
          onDidDismiss={(params) => {
            if (params && params.detail.data.shouldReload) getPage({ pageNumber: 1 })
            setModalReportOpener((s) => ({ ...s, isOpen: false }))
          }}
          notNeededToConfirm={false}
        />

        <ModalFilterStatePatients
          trigger="trigger-modal-patients"
          modal={modalFilterPatients}
          isOpen={isFilterOpen}
          patientsService={props.patientsService}
          onDidDismiss={(filter) => {
            const data = filter.detail.data
            setIsFilterOpen(false)
            if (data) setChips(data)
          }}
          filtersApplied={chips}
        />
      </IonContent>
      <Footer />
    </IonPage>
  )
}

export default DashboardPage

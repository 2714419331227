import { Account } from 'domain/model/account'
import { UserInfo } from 'infra/services/usecases/get-user-info'
import { atom } from 'recoil'

export interface CurrentAccountState {
  getCurrentAccount: () => UserInfo | null
  setCurrentAccount: (account: UserInfo) => void
}

export const currentAccountState = atom<CurrentAccountState>({
  key: 'currentAccountState',
  default: {
    getCurrentAccount: null as unknown as () => Account | null,
    setCurrentAccount: null as unknown as (account: UserInfo) => void,
  },
})

import { GetUsers, GetUsersParams, GetUsersResponse } from 'domain/usecases/get-users'
import { baseUrl, usersUrl } from 'infra/config/aws-env'
import { HttpClient } from 'infra/http/usecases/http-client'

export class UserInternalService implements GetUsers {
  constructor(private baseUrl: string, private httpClient: HttpClient) {}

  getUsers(params: GetUsersParams): Promise<GetUsersResponse> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .request({
          type: 'get',
          url: `${baseUrl}${usersUrl.base}?search=${params.search}&page=${params.page}&size=${params.size}`,
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((data) => resolve(data))
        .catch((error) => reject(error))
    })
  }
}

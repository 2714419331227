import { SetLocalStorage } from './get-local-storage copy'
import { RemoveLocalStorage } from './remove-local-storage'
import { GetLocalStorage } from './set-local-storage'
import { Cache } from './usecases/cache'

export class CacheLocalStorage implements Cache {
  constructor(
    private getLocalStorage: GetLocalStorage,
    private setLocalStorage: SetLocalStorage,
    private removeLocalStorage: RemoveLocalStorage
  ) {}

  get(key: string): string | number | object | null {
    return this.getLocalStorage.get(key)
  }

  set(key: string, value: string): void {
    this.setLocalStorage.set(key, value)
  }

  remove(): void {
    this.removeLocalStorage.remove()
  }
}

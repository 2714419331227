/* eslint-disable react/react-in-jsx-scope */
import { IonInput, IonItem, IonLabel } from '@ionic/react'
import { closeOutline, eyeOffOutline, eyeOutline } from 'ionicons/icons'
import { useState } from 'react'
import ButtonIcon from '../buttons/button-icon'
import './input-autofill.scss'

type InputAutofill = React.ComponentPropsWithRef<typeof IonInput> & {
  label: string
  hasText: boolean
  clearButton: boolean
  eyeButton: boolean
  inputType: 'text' | 'password'
  onClearText: () => void
}

function InputAutofill(props: InputAutofill) {
  const [openEye, setOpenEye] = useState(false)
  const [inputType, setInputType] = useState<'text' | 'password'>(props.inputType)

  return (
    <IonItem className="input-autofill" lines="none">
      <IonLabel position="stacked">{props.label}</IonLabel>

      <IonInput {...props} type={inputType} />

      {props.clearButton && props.hasText ? (
        <ButtonIcon
          fill="clear"
          slot="end"
          className="right-button align-center"
          icon={closeOutline}
          onClick={() => props.onClearText()}
          color="medium"
          style={{ alignSelf: 'center' }}
        />
      ) : undefined}

      {props.eyeButton ? (
        <ButtonIcon
          fill="clear"
          slot="end"
          className="right-button align-center"
          icon={openEye ? eyeOffOutline : eyeOutline}
          onClick={() => {
            setInputType(openEye ? 'password' : 'text')
            setOpenEye(!openEye)
          }}
          color="medium"
          style={{ alignSelf: 'center' }}
        />
      ) : undefined}
    </IonItem>
  )
}

export default InputAutofill

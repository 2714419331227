import { IonChip } from '@ionic/react'
import React from 'react'
import './custom-chip.scss'

interface CustomChipProps {
  color: 'warning' | 'success' | 'danger'
  className?: string
}

const CustomChip: React.FC<CustomChipProps> = (props) => {
  return <IonChip className={`custom-chip ${props.className} ${props.color}`}>{props.children}</IonChip>
}

export default CustomChip

import { IonButton, IonIcon } from '@ionic/react'
import React from 'react'

type ButtonIconProps = React.ComponentPropsWithoutRef<typeof IonButton> & {
  icon: string
}

const ButtonIcon: React.FC<ButtonIconProps> = (props: ButtonIconProps) => {
  return (
    <IonButton {...props}>
      <IonIcon slot="icon-only" icon={props.icon} />
    </IonButton>
  )
}

export default ButtonIcon

import { Treatment, TreatmentDetail } from 'domain/usecases/get-report'
import React from 'react'
import DefaultCard from '../default-card/default-card'
import './prescribed-therapy.scss'

interface PrescribedTherapyItemProps {
  title: string
  details: TreatmentDetail
}

const PrescribedTherapyItem: React.FC<PrescribedTherapyItemProps> = (props) => {
  return (
    <div className="pti-item">
      <span className="pti-title">{props.title}</span>
      {props.details.description && <span className="pti-detail-item">{props.details.description.join(' - ')}</span>}
      {props.details.locations && (
        <span className="pti-detail-item">
          <b>Locations: </b>
          {props.details.locations.join(' - ')}
        </span>
      )}
      {props.details.phase && (
        <span className="pti-detail-item">
          <b>Phase:</b> {props.details.phase}
        </span>
      )}
      {props.details.status && (
        <span className="pti-detail-item">
          <b>Status: </b>
          {props.details.status}
        </span>
      )}
    </div>
  )
}

interface PrescribedTherapyProps {
  isLoading: boolean
  treatments: Treatment[]
}

const PrescribedTherapy: React.FC<PrescribedTherapyProps> = (props) => {
  return (
    <DefaultCard isLoading={props.isLoading} title="Prescribed Targeted Therapy">
      <div className="ptt-wrapper">
        {!props.isLoading &&
          props.treatments &&
          props.treatments.map((treatment, key) => (
            <PrescribedTherapyItem key={key} details={treatment.detail} title={treatment.name} />
          ))}

        {props.isLoading || !props.treatments || !props.treatments.length ? (
          <span className="ptt-empty">
            No prescription selected yet. <br />
            You can select it from the report.
          </span>
        ) : undefined}
      </div>
    </DefaultCard>
  )
}

export default PrescribedTherapy

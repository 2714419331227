import { IonIcon } from '@ionic/react'
import { documentTextOutline } from 'ionicons/icons'
import React from 'react'
import './failed-report.scss'

interface FailedReportProps {
  title: string
  body: JSX.Element
}

const FailedReport: React.FC<FailedReportProps> = (props) => {
  return (
    <div className="failed-report">
      <div className="icon">
        <IonIcon icon={documentTextOutline} />
      </div>
      <div>
        <span className="fr-title">
          <b>{props.title}</b>
        </span>
        <span className="fr-subtitle">{props.body}</span>
      </div>
    </div>
  )
}

export default FailedReport

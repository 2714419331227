import { IonIcon, IonSpinner } from '@ionic/react'
import { ReportStatus } from 'domain/usecases/get-report'
import { checkmarkOutline } from 'ionicons/icons'
import { NotFound, Title } from 'presentation/components'
import React from 'react'
import { useRecoilValue } from 'recoil'
import { reportAtom } from '../../report-atom'
import './immunotherapy-markers.scss'

interface ImmunotherapyMarkersProps {
  options: {
    tumor_mutational_burden: string | undefined
    tmb_percentile: string | undefined
    microsatellite_instability_status: string | undefined
  }
}

export const ImmunotherapyMarkers: React.FC<ImmunotherapyMarkersProps> = (props) => {
  const { isLoadingReport, report } = useRecoilValue(reportAtom)

  return (
    <section className="immunotherapy-markers-section">
      {isLoadingReport ? (
        <div className="im-spinner">
          <IonSpinner name="dots" />
        </div>
      ) : (
        <>
          <Title size="h2" className="first-line">
            Immunotherapy Markers
          </Title>
          {report?.status === ReportStatus.processing ? (
            <NotFound>The report is being processed</NotFound>
          ) : (
            <>
              <div className="innercard">
                <div className="tumor-mutational">
                  <div className="tm-title">
                    <b>Tumor Mutational Burden</b>
                    <div className="chip">
                      <span>{props.options.tumor_mutational_burden}</span>
                      <span className="chip-info">{props.options.tmb_percentile || 'Unknown'}</span>
                    </div>
                  </div>
                </div>

                <div className="tumor-mutational">
                  <div className="tm-title">
                    <b>Microsatellite Instability Status</b>
                    <div className={'chip'}>{props.options.microsatellite_instability_status || 'Unknown'}</div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </section>
  )
}

export type PatientStatus = 'progressing' | 'responding'

export type PatientItem = {
  MRN: null | string
  birthdate: null | string
  cancer_type: null | string
  cancer_stage: null | string
  changed: null | string
  name: null | string
  sex: null | string
  status: PatientStatus
  treatment: null | string
  read: boolean
  age: number | null
  last_report_id: string | null
}

export type PatientsItemDto = {
  items: PatientItem[]
  page: number
  size: number
  total: number
}

export type OrderByListPatients = keyof Omit<PatientItem, 'read'>

export type ListPatientsParams = {
  name: string
  search: string
  status: string[]
  treatment: string[]
  cancer_type: string[]
  genomic_variant: string[]
  order_by: {
    field: OrderByListPatients
    order: 'ASC' | 'DESC'
  }
  size: number
  page: number
}

export const toStatus = {
  progressing: 'Progressing',
  responding: 'Responding',
}
export interface ListPatients {
  getPatients(params: Partial<ListPatientsParams>): Promise<PatientsItemDto>
}

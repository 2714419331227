import { Validation } from './usecases/validation'

export class EmailValidation implements Validation {
  private emailRegex = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/

  validate(email: string): string | null {
    if (this.emailRegex.test(email)) return null
    return 'Email format error. It must be like: user@domain.com'
  }
}

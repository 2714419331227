import GenePage from 'presentation/pages/gene/gene'
import React from 'react'
import { makeInsightsService } from '../services/insights-service-factory'
import { makeReportsService } from '../services/reports-service-factory'

const reportsService = makeReportsService()
const insightsService = makeInsightsService()

const makeGenePage: React.FC = () => {
  return <GenePage reportsService={reportsService} insightsService={insightsService} />
}

export default makeGenePage

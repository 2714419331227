import { IonButton, IonIcon } from '@ionic/react'
import { PatientLastReports } from 'domain/usecases/get-patient'
import { labs } from 'helpers/labs'
import { arrowForwardOutline, documentTextOutline } from 'ionicons/icons'
import { route } from 'main/routes'
import moment from 'moment'
import React from 'react'
import { useHistory } from 'react-router'
import DefaultCard from '../default-card/default-card'
import './latest-report.scss'

interface LatestReportProps {
  latestReport: PatientLastReports
  isLoading: boolean
  reportNumber: number
}

const LatestReport: React.FC<LatestReportProps> = (props) => {
  const fallback = 'Unknown'

  const navigate = useHistory()

  const getDateFormat = (str: string) => moment(str).format('MMM DD YYYY')

  const goToReportPage = () => navigate.push(`${route.report.url}/${props.latestReport.report_id}`)

  return (
    <DefaultCard isLoading={props.isLoading} title={'Latest Report'}>
      <div className="latest-report-card">
        <div className="icon-wrapper">
          <div className="icon">
            <IonIcon icon={documentTextOutline} />
          </div>
        </div>

        {props.latestReport && (
          <>
            <span className="text-block pretitle">
              Report {props.reportNumber ? `#${props.reportNumber}` : fallback}{' '}
              {getDateFormat(props.latestReport && props.latestReport.report_date)}
            </span>
            <span className="text-block lrc-title">{props.latestReport.diagnosis}</span>
            <span className="text-block subtitle">Lab {labs[props.latestReport && props.latestReport.lab]}</span>
            <IonButton size="small" expand="block" onClick={goToReportPage}>
              <IonIcon icon={arrowForwardOutline} slot="end" />
              View Summary Report
            </IonButton>
          </>
        )}
      </div>
    </DefaultCard>
  )
}

export default LatestReport

import { TypeaheadItem } from 'presentation/components/typeahead/typeahead'
import { atom } from 'recoil'

export interface ModalEditReportHeaderState {
  cancerType: TypeaheadItem
  stages: TypeaheadItem
  status: TypeaheadItem
  priorTreatments: string | null
  payer: TypeaheadItem
  comorbidities: string | null
}

export const modalEditReportHeaderAtom = atom<ModalEditReportHeaderState>({
  key: 'modalEditReportHeaderAtom',
  default: {
    cancerType: null,
    stages: null,
    status: null,
    priorTreatments: null,
    payer: null,
    comorbidities: null,
  },
})

import React from 'react'
import './title.scss'

export type TitleProps = {
  size: 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
  className?: string
}

const Title: React.FC<TitleProps> = (props) => {
  return (
    <div className="title-custom">
      {props.size === 'h1' && <h1 {...props}>{props.children}</h1>}
      {props.size === 'h2' && <h2 {...props}>{props.children}</h2>}
      {props.size === 'h3' && <h3 {...props}>{props.children}</h3>}
      {props.size === 'h4' && <h4 {...props}>{props.children}</h4>}
      {props.size === 'h5' && <h5 {...props}>{props.children}</h5>}
    </div>
  )
}

export default Title

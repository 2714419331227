import { ResetPassword } from 'domain/usecases/reset-password'

export class ResetPasswordService implements ResetPassword {
  remote: ResetPassword

  constructor(remote: ResetPassword) {
    this.remote = remote
  }

  verifyCode(username: string, code: string, password: string): Promise<unknown> {
    return this.remote.verifyCode(username, code, password)
  }
}

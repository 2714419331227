import { RemoteGetUserCognito } from 'infra/http/cognito/remote-get-user-cognito'
import { GetUserInfo, UserInfo } from 'infra/services/usecases/get-user-info'
import { clearCurrentAccountAdapter, getCurrentAccountAdapter } from 'main/adapters/current-account-adapter'
import { LogoutAccount } from './usecases/logout'

export class UserService implements GetUserInfo, LogoutAccount {
  private cognitoUser = new RemoteGetUserCognito()

  public constructor(private remote: GetUserInfo) {}

  getUserInfo(): Promise<UserInfo> {
    return new Promise((resolve, reject) => {
      this.remote
        .getUserInfo()
        .then((user: UserInfo) => resolve(user))
        .catch(() => reject())
    })
  }

  logout(navigate: () => void): void {
    const email = getCurrentAccountAdapter().email
    this.cognitoUser.getCognitoUser(email).signOut()
    clearCurrentAccountAdapter()
    navigate()
  }
}

import { IonCheckbox, IonSpinner } from '@ionic/react'
import { isset } from 'helpers/string-isset'
import { ClinicsService } from 'infra/services/clinics-service'
import { RolesService } from 'infra/services/roles-service'
import { GenericSelector } from 'infra/services/usecases/generic-selector'
import { route } from 'main/routes'
import { SignupFormValidation } from 'main/validations/usecases/signup-form-validation'
import { Validation } from 'main/validations/usecases/validation'
import {
  ButtonLink,
  ButtonRound,
  DefaultInput,
  FlexGrid,
  Message,
  PasswordInput,
  Select,
  Title,
} from 'presentation/components'
import { SelectOption } from 'presentation/components/select/select'
import { useSkipFirstRender } from 'presentation/hooks/skip-first-render'
import ModalPrivacyPolicy from 'presentation/pages/modal-privacy-policy/modal-privacy-policy'
import ModalTerms from 'presentation/pages/modal-terms/modal-terms'
import React, { useEffect, useRef, useState } from 'react'
import { useRecoilState } from 'recoil'
import { SignupFormState, signupFormState } from './signup-atom'
import './signup-form.scss'

type SignupFormProps = {
  onSubmit: () => void
  validations: SignupFormValidation
  clinicsService: ClinicsService
  rolesService: RolesService
}

enum FormFields {
  name = 'name',
  email = 'email',
  password = 'password',
  role = 'role',
  clinic = 'clinic',
}

export const SignupForm: React.FC<SignupFormProps> = (props) => {
  const [signupState, setSignupState] = useRecoilState(signupFormState)
  const isFirstRender = useSkipFirstRender()
  const modalTerms = useRef<HTMLIonModalElement>(null)
  const modalPrivacyPolicy = useRef<HTMLIonModalElement>(null)
  const [clinics, setClinics] = useState<SelectOption[]>([])
  const [roles, setRoles] = useState<SelectOption[]>([])

  const toSelcetOption = (options: GenericSelector[]) => {
    return options.map((option) => ({ label: option.name, value: option.name }))
  }

  const getClinics = () => {
    props.clinicsService
      .getClinics()
      .then((clinics) => setClinics(toSelcetOption(clinics)))
      .catch(() => setClinics([]))
  }

  const getRoles = () => {
    props.rolesService
      .getRoles()
      .then((roles) => setRoles(toSelcetOption(roles)))
      .catch(() => setRoles([]))
  }

  useEffect(() => {
    getClinics()
    getRoles()
  }, [])

  const validate = (fieldName: keyof SignupFormState) => {
    if (!isFirstRender) {
      const updateState = (stateFieldName: keyof SignupFormState, value: string | null) => {
        setSignupState((current) => {
          return {
            ...current,
            [stateFieldName]: value,
          }
        })
      }

      const validateField = (validations: Validation[], value: string) =>
        props.validations.getValidationError(validations, value)

      switch (fieldName) {
        case FormFields.name:
          updateState('nameError', validateField(props.validations.name, signupState.name))
          break
        case FormFields.email:
          updateState('emailError', validateField(props.validations.email, signupState.email))
          break
        case FormFields.password:
          updateState('passwordError', validateField(props.validations.password, signupState.password))
          break
        case FormFields.role:
          updateState('roleError', validateField(props.validations.role, signupState.role))
          break
        case FormFields.clinic:
          updateState('clinicError', validateField(props.validations.clinic, signupState.clinic))
          break
        default:
          break
      }
    }
  }

  useEffect(() => validate(FormFields.name), [signupState.name])
  useEffect(() => validate(FormFields.email), [signupState.email])
  useEffect(() => validate(FormFields.password), [signupState.password])
  useEffect(() => validate(FormFields.role), [signupState.role])
  useEffect(() => validate(FormFields.clinic), [signupState.clinic])

  const isSubmitDisabled = () => {
    const isValid = Boolean(
      signupState.nameError === null &&
        signupState.emailError === null &&
        signupState.passwordError === null &&
        signupState.roleError === null &&
        signupState.clinicError === null &&
        isset(signupState.name) &&
        isset(signupState.email) &&
        isset(signupState.password) &&
        isset(signupState.role) &&
        isset(signupState.clinic) &&
        !!signupState.acceptTerms
    )

    return !isValid
  }

  const onSubmit = (event: React.MouseEvent<HTMLIonButtonElement>) => {
    event.stopPropagation()
    props.onSubmit()

    return false
  }

  const updateCheckbox = () => {
    setSignupState((current) => {
      return {
        ...current,
        acceptTerms: !current.acceptTerms,
      }
    })
  }

  return (
    <section className="signup-form">
      <Title size="h1" className="title-center">
        Sign Up
      </Title>
      <p>
        Let{"'"}s set up your account so you can start working more efficiently <br />
        with Precision Medicine Reports
      </p>

      <div style={{ marginBottom: 18 }}>
        <DefaultInput
          placeholder="Your Name"
          labelText="Complete Name"
          name={FormFields.name}
          value={signupState.name}
          state={signupState}
          setState={setSignupState}
        />
        {isset(signupState.nameError) && <Message color="error">{signupState.nameError}</Message>}
      </div>

      <div style={{ marginBottom: 18 }}>
        <DefaultInput
          placeholder="email@email.com"
          labelText="Email"
          name={FormFields.email}
          value={signupState.email}
          state={signupState}
          setState={setSignupState}
          type="email"
        />
        {isset(signupState.emailError) && <Message color="error">{signupState.emailError}</Message>}
      </div>

      <div style={{ marginBottom: 18 }}>
        <PasswordInput
          placeholder="E.g. Passw0rd?"
          labelText="Password"
          name={FormFields.password}
          value={signupState.password}
          state={signupState}
          setState={setSignupState}
        />
        {isset(signupState.passwordError) && <Message color="error">{signupState.passwordError}</Message>}
      </div>

      <div style={{ marginBottom: 18 }}>
        <Select
          placeholder="Your Role"
          options={roles}
          label="Your Role"
          name={FormFields.role}
          value={signupState.role}
          state={signupState}
          setState={setSignupState}
        />

        {isset(signupState.roleError) && <Message color="error">{signupState.roleError}</Message>}
      </div>

      <div>
        <Select
          placeholder="Clinic"
          options={clinics}
          label="Clinic"
          name={FormFields.clinic}
          value={signupState.clinic}
          state={signupState}
          setState={setSignupState}
        />
        {isset(signupState.clinicError) && <Message color="error">{signupState.clinicError}</Message>}
      </div>

      <div className="signup-form-therms">
        <FlexGrid gap={6} align="center">
          <IonCheckbox color="secondary" checked={signupState.acceptTerms} onIonChange={updateCheckbox} /> I agree with
          OncoRx
          <ButtonLink id="open-modal-terms">Terms</ButtonLink>
          and
          <ButtonLink id="open-modal-privacy-policy">Privacy Policy</ButtonLink>
        </FlexGrid>
      </div>
      {!signupState.acceptTerms && (
        <Message color="error">We need you to accept the terms and our privacy policy</Message>
      )}
      {signupState.remoteError && signupState.remoteError.length && (
        <Message color="error">{signupState.remoteError}</Message>
      )}

      <ButtonRound type="submit" onClick={onSubmit} disabled={isSubmitDisabled() || signupState.isLoading}>
        {!signupState.isLoading && 'Sign Up'}
        {signupState.isLoading && <IonSpinner />}
      </ButtonRound>

      <div className="signup-form-login-link">
        <FlexGrid gap={8} align="center">
          Already have an account? <ButtonLink routerLink={route.login.url}>Log in</ButtonLink>
        </FlexGrid>
      </div>

      <ModalTerms trigger="open-modal-terms" modal={modalTerms} class="modal-full-size" />

      <ModalPrivacyPolicy trigger="open-modal-privacy-policy" modal={modalPrivacyPolicy} class="modal-full-size" />
    </section>
  )
}

export default SignupForm

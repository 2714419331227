import { IonButton, IonContent, IonFooter, IonModal, IonToolbar } from '@ionic/react'
import { PatientsService } from 'infra/services/patients-service'
import { filterCircle } from 'ionicons/icons'
import { ModalTitle, Typeahead } from 'presentation/components'
import { GroupedOption, TypeaheadItem } from 'presentation/components/typeahead/typeahead'
import React, { useCallback, useEffect, useState } from 'react'
import { useRecoilState, useResetRecoilState } from 'recoil'
import { HeadingFilter } from '../heading/heading-atom'
import { modalFilterAtom } from './modal-filter-patients-atom'
import './modal-filter-patients.scss'

type ModalFilterStatePatients = React.ComponentPropsWithoutRef<typeof IonModal> & {
  modal: React.RefObject<HTMLIonModalElement>
  trigger: string
  patientsService: PatientsService
  filtersApplied?: HeadingFilter
}

const ModalFilterStatePatients: React.FC<ModalFilterStatePatients> = (props) => {
  const [filterState, setFilterState] = useRecoilState(modalFilterAtom)
  const clearAll = useResetRecoilState(modalFilterAtom)

  const [patients, setPatients] = useState<TypeaheadItem[]>([])
  const [isLoadingPatients, setIsLoadingPatients] = useState(false)

  const [genomicVariations, setGenomicVariations] = useState<TypeaheadItem[]>([])
  const [isLoadingGenomic, setIsLoadingGenomic] = useState(false)

  const [cancerTypes, setCancerTypes] = useState<GroupedOption[]>([])
  const [isLoadingCancerTypes, setLoadingCancerTypes] = useState(false)

  const [treatments, setTreatments] = useState<TypeaheadItem[]>([])
  const [isLoadingTreatments, setLoadingTreatments] = useState(false)

  const makeTypeahead = (label: string, value: number | string) => ({ value, label })
  const status = [makeTypeahead('Responding', 'responding'), makeTypeahead('Progressing', 'progressing')]

  const getPatients = (search: string) => {
    setIsLoadingPatients(true)
    props.patientsService
      .getPatients({
        page: 1,
        size: 10,
        search,
      })
      .then((result) => {
        const newItens = result.items.map((item) => ({ label: item.name, value: item.MRN }))
        setPatients(newItens)
      })
      .catch(() => setPatients([]))
      .finally(() => setIsLoadingPatients(false))
  }

  const getGenomicVariants = (search: string) => {
    setIsLoadingGenomic(true)
    props.patientsService
      .getGenomicVariants({
        page: 1,
        size: 10,
        search: search || '',
      })
      .then((result) => {
        setGenomicVariations(result)
      })
      .catch(() => setGenomicVariations([]))
      .finally(() => setIsLoadingGenomic(false))
  }

  const getCancerTypes = () => {
    setLoadingCancerTypes(true)
    props.patientsService
      .getCancerTypes({ from_reports: true })
      .then((result) => {
        if (result) {
          const newItens: GroupedOption[] = result.map((item) => ({ label: item.category, options: item.options }))
          setCancerTypes(newItens)
        } else setCancerTypes([])
      })
      .catch(() => setCancerTypes([]))
      .finally(() => setLoadingCancerTypes(false))
  }

  const getTreatments = () => {
    setLoadingTreatments(true)
    props.patientsService
      .getTreatments()
      .then((result) => {
        if (result) {
          const newItens = result.map((item) => ({ label: item, value: item }))
          setTreatments(newItens)
        } else {
          setTreatments([])
        }
      })
      .catch(() => setTreatments([]))
      .finally(() => setLoadingTreatments(false))
  }

  const handleDebounceFn = useCallback((value: { [key: string]: string }) => {
    const adapter: { key: keyof HeadingFilter; value: string } = {
      key: Object.keys(value)[0] as keyof HeadingFilter,
      value: Object.values(value)[0],
    }

    switch (adapter.key) {
      case 'patient':
        getPatients(adapter.value)
        break
      case 'genomicVariation':
        getGenomicVariants(adapter.value)
        break
      case 'cancerType':
        getCancerTypes()
        break
      case 'treatment':
        getTreatments()
        break
      default:
        break
    }
  }, [])

  useEffect(() => {
    setFilterState(props.filtersApplied as HeadingFilter)
  }, [props.filtersApplied])

  return (
    <IonModal ref={props.modal} {...props}>
      <IonContent className="ion-padding">
        <ModalTitle icon={filterCircle} label="Search Filters" />

        <Typeahead
          label="Patient"
          placeholder="Type a Name or an MRN to filter the list"
          options={patients}
          isLoading={isLoadingPatients}
          value={filterState.patient}
          onMenuOpen={() => getPatients('')}
          onTypeaheadDebounce={(patient) => handleDebounceFn({ patient })}
          onChange={(patient) => setFilterState((s) => ({ ...s, patient } as HeadingFilter))}
        />
        <Typeahead
          label="Cancer Type"
          placeholder="Select..."
          options={cancerTypes}
          isLoading={isLoadingCancerTypes}
          onTypeaheadDebounce={(cancerType) => handleDebounceFn({ cancerType })}
          onMenuOpen={() => handleDebounceFn({ cancerType: '' })}
          onChange={(cancerType) => setFilterState((s) => ({ ...s, cancerType } as HeadingFilter))}
          value={filterState.cancerType}
        />
        <Typeahead
          label="Treatment"
          placeholder="Select..."
          options={treatments}
          isLoading={isLoadingTreatments}
          onTypeaheadDebounce={(treatment) => handleDebounceFn({ treatment })}
          onMenuOpen={() => handleDebounceFn({ treatment: '' })}
          onChange={(treatment) => setFilterState((s) => ({ ...s, treatment } as HeadingFilter))}
          value={filterState.treatment}
        />
        <Typeahead
          label="Status"
          placeholder="Select..."
          options={status}
          onTypeaheadDebounce={(status) => handleDebounceFn({ status })}
          onMenuOpen={() => handleDebounceFn({ status: '' })}
          onChange={(status) => setFilterState((s) => ({ ...s, status } as HeadingFilter))}
          value={filterState.status}
        />
        <Typeahead
          label="Genomic Variation"
          placeholder="Select..."
          options={genomicVariations}
          isLoading={isLoadingGenomic}
          onTypeaheadDebounce={(genomicVariation) => handleDebounceFn({ genomicVariation })}
          onMenuOpen={() => handleDebounceFn({ genomicVariation: '' })}
          onChange={(genomicVariation) => setFilterState((s) => ({ ...s, genomicVariation } as HeadingFilter))}
          value={filterState.genomicVariation}
        />
      </IonContent>

      <IonFooter>
        <IonToolbar>
          <IonButton slot="start" fill="clear" onClick={() => clearAll()}>
            Clear All
          </IonButton>
          <IonButton
            slot="end"
            fill="outline"
            onClick={() => {
              props.modal.current?.dismiss()
            }}
          >
            Cancel
          </IonButton>
          <IonButton
            slot="end"
            fill="solid"
            onClick={() => {
              const filter = filterState
              props.modal.current?.dismiss(filter)
            }}
          >
            Apply
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  )
}

export default ModalFilterStatePatients

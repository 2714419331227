import { AccessTokenCache } from 'infra/cache/set-access-token-cache'
import React from 'react'
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom'
import { route } from '.'

type PrivateRouteProps = RouteProps & {
  accessToken: AccessTokenCache
}

const PrivateRoute: React.FC<PrivateRouteProps> = (props) => {
  const accessToken = props.accessToken.get()

  const { search } = useLocation()

  return accessToken && accessToken.length ? <Route {...props} /> : <Redirect to={`${route.login.url}${search}`} />
}

export default PrivateRoute

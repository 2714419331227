import { IonButton, IonFooter, IonSpinner, IonToolbar, useIonToast } from '@ionic/react'
import { PatientsService } from 'infra/services/patients-service'
import { ReportsService } from 'infra/services/reports-service'
import { documentText } from 'ionicons/icons'
import { DefaultInput, ModalTitle, Typeahead } from 'presentation/components'
import { TypeaheadItem } from 'presentation/components/typeahead/typeahead'
import React, { useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { modalNewReportAtom, ModalNewReportState, reportPatientAtom } from '../modal-new-report-atom'

interface ConfirmPatientProps {
  patientsService: PatientsService
  reportsService: ReportsService
  onConfirmPatient: () => void
  onNewPatient: () => void
  dismiss: () => void
}

const ConfirmPatient: React.FC<ConfirmPatientProps> = (props) => {
  const { reportExtract } = useRecoilValue<ModalNewReportState>(modalNewReportAtom)
  const [patients, setPatients] = useState<TypeaheadItem[]>([])
  const [isLoadingPatients, setIsLoadingPatients] = useState<boolean>(false)
  const [confirmPatient, setConfirmPatient] = useRecoilState(reportPatientAtom)
  const [loading, setLoading] = useState(false)
  const [presetToast] = useIonToast()

  const getPatients = (search?: string) => {
    setIsLoadingPatients(true)
    props.patientsService
      .getPatients({
        page: 1,
        size: 10,
        search,
      })
      .then((result) => {
        const newItens = result.items.map((item) => ({ label: item.name, value: item.MRN }))
        setPatients(newItens)
      })
      .catch(() => setPatients([]))
      .finally(() => setIsLoadingPatients(false))
  }

  const handleConfirmPatient = () => {
    setLoading(true)
    props.reportsService
      .confirmPatientReport({ ...confirmPatient, reportId: reportExtract?.report_id as string })
      .then(() => props.onConfirmPatient())
      .catch(() => showToastError('There was an error trying to confirm patient, try again later.'))
      .finally(() => setLoading(false))
  }

  const showToastError = (message: string) => {
    presetToast({
      message,
      duration: 5000,
      color: 'danger',
    })
  }

  return (
    <>
      <section className="ion-padding">
        <ModalTitle label="Confirm Patient" icon={documentText} />

        <span className="confirm-patient-subtitle">Select an existing patient name to confirm the merge</span>

        <Typeahead
          label="Patient"
          placeholder="Select..."
          options={patients}
          isLoading={isLoadingPatients}
          value={{ label: confirmPatient.name, value: confirmPatient.MRN }}
          onMenuOpen={() => getPatients()}
          onTypeaheadDebounce={(search) => getPatients(search)}
          onChange={(patient) => {
            const typeaheadItem = patient as TypeaheadItem

            setConfirmPatient((s) => ({
              ...s,
              name: typeaheadItem?.label as string,
              MRN: typeaheadItem?.value as string,
            }))
          }}
        />

        <DefaultInput
          name="MRN"
          state={confirmPatient}
          value={confirmPatient.MRN}
          setState={setConfirmPatient}
          labelText="Medical Record Number"
          placeholder="MRN"
          notEditable={true}
          notCleanable={true}
        />
      </section>

      <IonFooter>
        <IonToolbar>
          <IonButton slot="end" fill="outline" onClick={props.dismiss}>
            Cancel
          </IonButton>

          <IonButton slot="end" fill="outline" onClick={props.onNewPatient}>
            Create New Patient
          </IonButton>

          <IonButton
            slot="end"
            onClick={handleConfirmPatient}
            disabled={!confirmPatient?.MRN || !confirmPatient.name || loading}
          >
            {loading ? <IonSpinner /> : 'Confirm'}
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </>
  )
}

export default ConfirmPatient

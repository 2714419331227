import { IonButton } from '@ionic/react'
import React, { useEffect } from 'react'
import './button-link.scss'

type ButtonLinkProps = React.ComponentPropsWithRef<typeof IonButton> & {
  noLink?: boolean
}

const ButtonLink: React.FC<ButtonLinkProps> = (props: ButtonLinkProps) => {
  const classList = ['button-link']
  const checkLink = () => props.noLink && classList.push('no-link')

  useEffect(() => {
    checkLink()
  }, [])

  return (
    <IonButton {...props} fill="clear" className={classList.join(' ')}>
      {props.children}
    </IonButton>
  )
}

export default ButtonLink

import { PatientItem } from './list-patients'

export interface GenomicVariants {
  id: number
  actionability: string | null
  alteration: string
  name: string
  type: string
}

export interface TreatmentDetail {
  description: string[] | null
  phase: string
  locations: string[]
  status: string | null
  link: string
  prescription: {
    drug: string
    image_url: string
    url: string
  }
}
export interface Treatment {
  id: number
  name: string
  type: string
  genes: string[]
  detail: TreatmentDetail
  relevance: string | null
  reason: string
  selected: boolean
}

export enum ReportStatus {
  processing = 'processing',
  processed = 'processed',
  failed = 'failed',
}
export interface Report {
  user_id: string
  created_at: string
  lab: string
  status: ReportStatus
  report_id: string
  lab_issued_id: string
  report_date: string
  biopsy_date: string
  patient: PatientItem
  diagnosis: string
  tumor_specimen: string
  specimen_type: string
  emr_link: string
  tumor_mutational_burden: string
  read: boolean
  tmb_percentile: string
  doctor_name: string
  microsatellite_instability_status: string
  genomic_variants: GenomicVariants[]
  treatments: Treatment[]
  selected_treatments: {
    note: string
    treatments: Treatment[]
  }
}

export interface GetReportParams {
  reportId: string
}

export interface GetReport {
  getReport: (params: GetReportParams) => Promise<Report>
}

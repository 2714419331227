/* eslint-disable indent */
import {
  AddNewReport,
  AddNewReportParams,
  ReportExtract,
  ValidationErrorResponse,
} from 'domain/usecases/add-new-report'
import { ConfirmNewPatientReport, ConfirmNewPatientReportParams } from 'domain/usecases/confirm-new-patient-report'
import { ConfirmPatientReport, ConfirmPatientReportParams } from 'domain/usecases/confirm-patient-report'
import { GetReport, GetReportParams, Report, Treatment } from 'domain/usecases/get-report'
import {
  GetReportComments,
  GetReportCommentsParams,
  GetReportCommentsResponse,
} from 'domain/usecases/get-report-comments'
import { GetReportPdfLink } from 'domain/usecases/get-report-pdf-link'
import {
  GetReportPermissions,
  GetReportPermissionsParams,
  GetReportPermissionsResponse,
} from 'domain/usecases/get-report-permissions'
import {
  ReplyToOncologist,
  ReplyToOncologistParams,
  ReplyToOncologistResponse,
} from 'domain/usecases/reply-to-oncologist'
import { SelectTreatment, SelectTreatmentParams } from 'domain/usecases/select-treatment'
import { ShareReportParams } from 'domain/usecases/share-report'
import { baseUrl, reportsUrl } from 'infra/config/aws-env'
import { HttpClient } from 'infra/http/usecases/http-client'
import { GeneDetailDto, GetGeneDetail, GetGeneDetailParams } from './usecases/get-gene-detail'
import { ReportAsRead, ReportAsReadParams } from './usecases/report-as-read'

export class ReportsService
  implements
    AddNewReport,
    ConfirmPatientReport,
    ConfirmNewPatientReport,
    GetReport,
    GetReportPdfLink,
    GetGeneDetail,
    SelectTreatment,
    ReportAsRead,
    GetReportPermissions,
    ReplyToOncologist,
    GetReportComments
{
  constructor(private readonly httpClient: HttpClient) {}

  addNewReport(params: AddNewReportParams): Promise<ReportExtract | ValidationErrorResponse> {
    return new Promise((resolve, reject) => {
      const formData = new FormData()

      formData.append('file', params.file)
      formData.append('report_lab', params.lab)

      this.httpClient
        .request({
          type: 'post',
          url: `${baseUrl}${reportsUrl.base}`,
          body: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((data) => resolve(data))
        .catch((error) => reject(error))
    })
  }

  confirmPatientReport(params: ConfirmPatientReportParams): Promise<unknown> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .request({
          type: 'put',
          url: `${baseUrl}${reportsUrl.base}/${params.reportId}/confirm-patient`,
          body: {
            MRN: params.MRN,
            name: params.name,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((data) => resolve(data))
        .catch((error) => reject(error))
    })
  }

  confirmNewPatientReport(params: ConfirmNewPatientReportParams): Promise<unknown> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .request({
          type: 'post',
          url: `${baseUrl}${reportsUrl.base}/${params.reportId}/confirm-patient`,
          body: {
            MRN: params.MRN,
            name: params.name,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((data) => resolve(data))
        .catch((error) => reject(error))
    })
  }

  getReport(params: GetReportParams): Promise<Report> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .request({
          type: 'get',
          headers: {
            'Content-type': 'application/json',
          },
          url: `${baseUrl}${reportsUrl.base}/${params.reportId}`,
        })
        .then((data) => resolve(data))
        .catch((error) => reject(error))
    })
  }

  getReportPdfLink(params: { reportId: string }): Promise<string> {
    return new Promise((resolve) => {
      this.httpClient
        .request({
          type: 'get',
          headers: {
            'Content-type': 'application/json',
          },
          url: `${baseUrl}${reportsUrl.base}/${params.reportId}/pdf`,
        })
        .then((link: { url: string }) => resolve(link.url))
        .catch(() => resolve(''))
    })
  }

  getGeneDetail(params: GetGeneDetailParams): Promise<GeneDetailDto> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .request({
          type: 'get',
          headers: {
            'Content-type': 'application/json',
          },
          url: `${baseUrl}${reportsUrl.base}/${params.reportId}/genomic-variant/${params.geneId}`,
        })
        .then((data) => resolve(data))
        .catch((err) => reject(err))
    })
  }

  selectTreatment(props: SelectTreatmentParams): Promise<unknown> {
    const newTreatments: { id: number }[] = []
    const newProps: SelectTreatmentParams = JSON.parse(JSON.stringify(props))

    props.treatments.map((t) => {
      if (!newTreatments.find((n) => n.id === t.id)) newTreatments.push(t)
    })

    newProps.treatments = newTreatments as Treatment[]

    return new Promise<void>((resolve, reject) => {
      this.httpClient
        .request({
          type: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          url: `${baseUrl}${reportsUrl.base}/${props.reportId}/select-treatment`,
          body: newProps,
        })
        .then(() => resolve())
        .catch(() => reject())
    })
  }

  markReportAsread(params: ReportAsReadParams): Promise<{ [key: string]: string }> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .request({
          headers: {
            'Content-Type': 'application/json',
          },
          type: 'post',
          url: `${baseUrl}${reportsUrl.base}/${params.MRN}/read`,
          body: {
            read: params.read,
          },
        })
        .then((result) => resolve(result))
        .catch(() => reject())
    })
  }

  shareReport(params: ShareReportParams): Promise<void> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .request({
          headers: {
            'Content-Type': 'application/json',
          },
          type: 'post',
          url: `${baseUrl}${reportsUrl.base}/${params.reportId}/share`,
          body: {
            users: params.users,
            message: params.message,
          },
        })
        .then((result) => resolve(result))
        .catch(() => reject())
    })
  }

  getReportPermissions(params: GetReportPermissionsParams): Promise<GetReportPermissionsResponse> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .request({
          type: 'get',
          headers: {
            'Content-type': 'application/json',
          },
          url: `${baseUrl}${reportsUrl.base}/${params.reportId}/permissions`,
        })
        .then((data) => resolve(data))
        .catch((err) => reject(err))
    })
  }

  replyToOncologist(params: ReplyToOncologistParams): Promise<ReplyToOncologistResponse> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .request({
          headers: {
            'Content-Type': 'application/json',
          },
          type: 'post',
          url: `${baseUrl}${reportsUrl.base}/${params.reportId}/comments`,
          body: {
            comment: params.comment,
          },
        })
        .then((result) => resolve(result))
        .catch(() => reject())
    })
  }

  getReportComments(params: GetReportCommentsParams): Promise<GetReportCommentsResponse> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .request({
          type: 'get',
          headers: {
            'Content-type': 'application/json',
          },
          url: `${baseUrl}${reportsUrl.base}/${params.reportId}/comments`,
        })
        .then((data) => resolve(data))
        .catch((err) => reject(err))
    })
  }
}

import { IonInputCustomEvent } from '@ionic/core'
import { IonInput } from '@ionic/react'
import debounce from 'lodash.debounce'
import React, { useCallback } from 'react'

type DebounceInputProps = React.ComponentPropsWithoutRef<typeof IonInput> & {
  debounceTime: number
  onDebounceChange: (str: string) => void
}

const DebounceInput: React.FC<DebounceInputProps> = (props) => {
  const debounceFn = useCallback(debounce(props.onDebounceChange as (valeu: string) => void, 500), [])

  const handleDebounce: (event: IonInputCustomEvent<InputEvent>) => void = (e) => {
    debounceFn(e.target.value as string)
  }

  return <IonInput {...props} onIonInput={handleDebounce} />
}

export default DebounceInput

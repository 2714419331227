/* eslint-disable @typescript-eslint/no-explicit-any */
import { IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react'
import React from 'react'
import './select.scss'

export type SelectOption = { value: unknown; label: string }

type SelectProps = React.ComponentPropsWithoutRef<typeof IonSelect> & {
  label: string
  placeholder: string
  options: SelectOption[]
  state: any
  setState: (state: any) => void
  name: string
  value: string
  onUpdate?: () => void
}

const Select: React.FC<SelectProps> = (props) => {
  const updateState = (stateKey: string, stateValue: unknown) => {
    props.setState({
      ...props.state,
      [stateKey]: stateValue,
    })

    props.onUpdate && props.onUpdate()
  }

  return (
    <IonItem lines="none" className="select-default">
      <IonLabel position="stacked">{props.label}</IonLabel>
      <IonSelect {...props} onIonChange={(e) => updateState(e.target.name, e.target.value)}>
        {props.options.map((item, key) => {
          return (
            <IonSelectOption key={key} value={item.value}>
              {item.label}
            </IonSelectOption>
          )
        })}
      </IonSelect>
    </IonItem>
  )
}

export default Select

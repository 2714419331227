import { IonCard, IonIcon } from '@ionic/react'
import { GenomicVariants } from 'domain/usecases/get-report'
import { searchSharp } from 'ionicons/icons'
import { route } from 'main/routes'
import { ButtonRound, Title } from 'presentation/components'
import React from 'react'
import { useHistory, useParams } from 'react-router'
import './genomic-variants-item.scss'

interface GenomicVariantsItemProps {
  item: GenomicVariants
  chart: JSX.Element | JSX.Element[]
  showFrquencyLabel: boolean
  onSearchLink: () => void
}

const GenomicVariantsItem: React.FC<GenomicVariantsItemProps> = (props) => {
  const params = useParams<{ id: string }>()
  const navigate = useHistory()

  const goToGeneDetailPage = () => {
    navigate.push(`${route.report.url}/${params.id}/gene/${props.item.id}`)
  }

  return (
    <IonCard className="genomic-item">
      <div className="gi-header-wrapper">
        <div className="gi-header">
          <div className="gi-title">
            <Title size="h3">{props.item.name}</Title>
            <IonIcon icon={searchSharp} onClick={props.onSearchLink} />
          </div>
          <small className="gi-subtitle">{props.item.alteration}</small>
        </div>

        {props.item.actionability ? (
          <div className="gi-action">
            Actionability: <span className={props.item.actionability}>{props.item.actionability}</span>
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className="gi-bottom">
        {props.showFrquencyLabel ? <span className="chart-title">Frequency by Cancer Type</span> : undefined}
        {props.chart}

        <ButtonRound size="small" expand="block" onClick={goToGeneDetailPage}>
          More Info
        </ButtonRound>
      </div>
    </IonCard>
  )
}

export default GenomicVariantsItem

import { Validation } from './usecases/validation'

export interface ValidationError {
  getValidationError(args: Validation[], value: string): string | null
}

export class GetValidationError implements ValidationError {
  getValidationError(args: Validation[], value: string): string | null {
    let errorMessage = null

    args.find((v) => {
      const hasErrorMessage = v.validate(value)

      errorMessage = hasErrorMessage
      if (hasErrorMessage) return v
    })

    return errorMessage
  }
}

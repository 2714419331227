import { IonButton, IonButtons, IonContent, IonHeader, IonPage, IonSpinner, IonTitle, IonToolbar } from '@ionic/react'
import { GeneInfoItem, GeneInfoResponse } from 'domain/usecases/get-genes-info'
import { InsightsService } from 'infra/services/insights-service'
import { ReportsService } from 'infra/services/reports-service'
import { GeneDetailDto } from 'infra/services/usecases/get-gene-detail'
import { route } from 'main/routes'
import { MutationAmplification, Viewport } from 'presentation/components'
import ChartBar from 'presentation/components/chart-bar/chart-bar'
import Footer from 'presentation/components/footer/footer'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import './gene.scss'

interface GenePageProps {
  reportsService: ReportsService
  insightsService: InsightsService
}

const GenePage: React.FC<GenePageProps> = (props) => {
  const [geneDetail, setGeneDetail] = useState<GeneDetailDto>()
  const [isLoading, setLoading] = useState<boolean>()

  const [isLoadingGeneInfo, setLoadingGeneInfo] = useState(false)
  const [geneInfo, setGeneInfo] = useState<GeneInfoItem[]>()

  const params = useParams<{ id: string; gene: string }>()
  const navigate = useHistory()
  const fallback = 'Unknown'

  const goBack = () => navigate.replace(`${route.report.url}/${params.id}`)

  const getGeneDetail = () => {
    setLoading(true)
    props.reportsService
      .getGeneDetail({
        geneId: params.gene,
        reportId: params.id,
      })
      .catch(() => goBack())
      .then((data) => setGeneDetail(data as GeneDetailDto))
      .finally(() => setLoading(false))
  }

  const getGenesInfo = () => {
    setLoadingGeneInfo(true)
    props.insightsService
      .getGenesInfo({
        genes: [geneDetail?.name as string],
      })
      .catch(() => goBack())
      .then((data) => {
        const dataExtract = data as GeneInfoResponse
        setGeneInfo(dataExtract[geneDetail?.name as string])
      })
      .finally(() => setLoadingGeneInfo(false))
  }

  useEffect(() => {
    getGeneDetail()
  }, [])

  useEffect(() => {
    if (geneDetail && geneDetail.name) getGenesInfo()
  }, [geneDetail?.name])

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{isLoading ? <IonSpinner name="dots" /> : geneDetail?.name}</IonTitle>
          <IonButtons slot="end">
            <IonButton color="primary" onClick={() => goBack()}>
              Close
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <Viewport>
          {isLoading ? (
            <div className="actionability-spinner">
              <IonSpinner name="dots" />
            </div>
          ) : (
            <div className="actionability-page">
              <section className="section actionability">
                <div className="actionability-title">
                  Actionability: <span className={geneDetail?.actionability}>{geneDetail?.actionability}</span>
                </div>

                <div className="actionability-header">
                  <span>
                    Alteration: <b>{geneDetail?.alteration || fallback}</b>
                  </span>
                  <span>
                    Transcript ID: <b>{geneDetail?.transcript_id || fallback}</b>
                  </span>
                  <span>
                    Coding sequence effect: <b>{geneDetail?.coding_sequence_effect || fallback}</b>
                  </span>
                  <span>
                    Variant allele frequency (%VAF): <b>{geneDetail?.variant_allele_frequency || fallback}</b>
                  </span>
                </div>
              </section>

              {!isLoadingGeneInfo ? (
                <>
                  <span className="text-title frequency-by-cancer-type">Alteration Frequency by cancer type</span>
                  <section className="chart-section">
                    <div className="chart-box">
                      {geneInfo?.map((info, key) => (
                        <ChartBar key={key} textInTheBar={false} geneInfo={info} />
                      ))}
                    </div>
                    <div className="chart-info">
                      <MutationAmplification />

                      <div className="chart-soruce">
                        Source:{' '}
                        <a
                          // eslint-disable-next-line max-len
                          href={`https://www.cbioportal.org/results/cancerTypesSummary?cancer_study_list=msk_met_2021&case_set_id=msk_met_2021_cnaseq&gene_list=${geneDetail?.name}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          cBioPortal
                        </a>
                      </div>
                    </div>
                  </section>
                </>
              ) : (
                <div className="loading-frequency">
                  <IonSpinner name="dots" />
                </div>
              )}
              {geneDetail?.details.map((detail, index) => {
                return (
                  <section className="section potential-treatment" key={index}>
                    <span className="text-title">{detail.title}</span>
                    <p>{detail.description}</p>
                  </section>
                )
              })}
            </div>
          )}
        </Viewport>
      </IonContent>

      <Footer />
    </IonPage>
  )
}

export default GenePage

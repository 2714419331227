import { atom } from 'recoil'
import { HeadingFilter } from '../heading/heading-atom'

export const modalFilterAtom = atom<HeadingFilter>({
  key: 'modalFilterAtom',
  default: {
    patient: null,
    cancerType: null,
    treatment: null,
    status: null,
    genomicVariation: null,
  },
})
